import slideToShow3 from '../slicksettings/slideToShow3CenterInfiniteFalse'
new Vue({
    el: '#news-container',
    data: {
        model:Object,
    },
    created() {
        this.getItems()
    },
    updated: function () {
        this.$nextTick(function () {
            if($(window).width() > 992) $('.slick-lg-trainings').slick(slideToShow3)
        })
    },
    methods:{
        async getItems() {
            var self = this
            const response = await $.ajax({
                url: `${api_url}news?active=1&highlight=1&sort=-date,-id`,
                headers: $headerAuth
            })
            self.model = response
        }
    }
})
