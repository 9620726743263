const { size } = require('lodash');

Vue.component('details-training', () => import('./details-training.vue'))

var slugtraining = $('#track-detail').data("slug");
new Vue({
    el: '#track',
    data: {
        model: {},
        track_id: null,
        completed: false,
        registered: false,
        openTraining: false,
        progress: 0,
        feedbacks: {},
        fields_feedback: {},
        question_finished: false,
        certificate: null,
        size_certificate: null,
        level_up: false,
        showTerms: false,
    },
    created: function(){
        this.get(slugtraining);
    },
    mounted: function(){},
    methods:{
        async setProgress(value){
            this.progress = value;
            if(this.progress == 100){
                $('#track-feedback').modal('show');
                this.saveProgressTrack(this.track_id);
            }
        },
        async setLevelUp(value){
            this.level_up = value;
        },
        showCertificate(){
            $('#training-certified').modal('show');
        },
        async get(slug){
            var self = this;

            let response = await axios.get(`${api_url}training/track/${slug}`, {headers : $headerAuth});
            var tracks = response.data;
            self.registered = (tracks.subscriber) ? true : false;
            self.progress = self.checkProgressTraining(tracks.trainings);
            self.question_finished = (tracks.subscriber) ? (((tracks.subscriber) && (tracks.subscriber.feedback.length == 0)) ? false : true) : false;
            self.certificate = (tracks.subscriber) ? tracks.subscriber.certificate : null;
            self.model = tracks;
            self.track_id = tracks.id;

            if(tracks.subscriber){
                if(!tracks.subscriber.completed){
                    self.saveProgressTrack(tracks.id);
                }
                else{
                    await this.checkConsultant();
                    var user = await $getUser;
                    var account = await $getAccount;
                    var d1 = new Date();
                    var d2 = (user.data.certification_expiredate) ? new Date(user.data.certification_expiredate) : new Date();
                    d2 = (user.data.certification_expiredate) ?  d2.getTime() : d2.setDate(d2.getDate() + 1);
                    if((self.progress == 100) && (account.category.id == 3)){
                        if((user.data.network == 0) && (d1.getTime() < d2)){
                            $('#track-terms').modal('show');
                        }
                    }
                }
            }
            self.getFeedback();
        },
        goto(refName) {
            var element = this.$refs[refName];
            var top =  element.offsetTop;
            if(top > 0) window.scrollTo(0, top);
        },
        subscribeTrack(id, trainings){
            var self = this;

            axios.put(
                `${api_url}training/track/subscribe/${id}`,
                {
                    'progress' : 0
                }
                ,{headers : $headerAuth}
            ).then(function (data) {
                self.registered = true;
                self.checkSubscribeTraining(trainings);
                self.saveProgressTrack(id);
            })
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao cadastrar na trilha.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });
        },
        checkProgressTraining(object){
            var count = 0;
            var size = (object) ? ((object.length <= 0)  ? 1 : object.length ) : 1;
            for (const key in object) {
                const element = object[key];
                if(element.subscriber){
                    if(element.subscriber.completed) count++;
                }
            }
            var total = (count * 100)/ size;
            return Math.trunc(total);
        },
        checkSubscribeTraining(object){
            for (const key in object) {
                const element = object[key];
                if(!element.subscriber){
                    this.subscribeTraining(element.id);
                }
            }
        },
        async subscribeTraining(id){
            var self = this;

            await axios.put(
                `${api_url}training/subscribe/${id}`,
                {
                    'time_watched' : 0,
                    'tried':0,
                }
                ,{headers : $headerAuth}
            ).then(function (data) {
            })
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao cadastrar no treinamento.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });
        },
        async saveProgressTrack(id){
            var self = this;
            self.progress = (self.progress > 100) ? 100 : parseInt(Math.ceil(self.progress));
            var completed = (self.progress >= 100) ? true : false;

            if(!id){
                await self.get(slugtraining);
                console.log(id);
            }

            const axiosWithoutInterceptors = axios.create();
            await axiosWithoutInterceptors.put(
                `${api_url}training/track/progress/${id}`,
                {
                    'progress' : parseInt(self.progress),
                    'completed' : completed,
                },
                {headers : $headerAuth}
            ).then(function (data) {
                if(completed){
                    $('#track-feedback').modal('show');
                    self.checkConsultant();
                }
                else{
                    self.checkSubscribeTraining(self.model.trainings);
                }
            })
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao salvar progresso.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });

        },
        async getFeedback(){
            var self = this;
            let response = await axios.get(`${api_url}training/track/feedback`, {headers : $headerAuth});
            self.feedbacks = response.data;
        },
        async sendFeedback(id){
            var self = this;

            if(self.fields_feedback.length < 0){
                $('#danger-message').show();
                $('#danger-message').html('*Preencher ao menos um campo.');
                return false;
            }

            await axios.post(
                `${api_url}training/track/${id}/feedback`,
                { 'questions': self.fields_feedback},
                {headers : $headerAuth}
            ).then(function (response) {
                self.question_finished = response.data.success;

                self.disableTabs();
                if(self.model.evaluations){
                    $('#button-evaluation-tab').addClass('active');
                    $('#pills-evaluation').addClass('active').addClass('show');
                    self.goto('evaluation');
                }
                else{
                    $('#button-certified-tab').addClass('active');
                    $('#training-certified').modal('show');
                    $('#pills-certified').addClass('active').addClass('show');
                }
            })
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao salvar progresso.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });
        },
        async createCertificate(id){
            let response = await axios.get(`${api_url}user/featured/${id}/certificate`, {headers : $headerAuth});
            this.certificate = response.data[0]['certificate'];
            this.size_certificate = (response.data[0]['size_certificate'] > 0) ? response.data[0]['size_certificate'] : this.getImageSize(this.certificate);
        },
        getImageSize(imageUrl){
            if (imageUrl) {
                var image = new Image();
                image.src = imageUrl;
                var height = image.height;
                var width = image.width;
                let a = width * height;
                let kb = (a * 2)/1024;
                let mb = kb/1024;
                return mb.toFixed(2);
            }
        },
        async checkConsultant(){
            var self = this;
            var count_completed = 0;
            var user = await $getAccount;

            let tracks = await axios.get(
                `${api_url}training/track?active=1`,
                {headers: $headerAuth}
            );

            await axios.get(
                `${api_url}training/track?sort=id&mycourse=true`,
                {headers: $headerAuth}
            ).then(function (data) {
                var mytracks = data.data.data;
                mytracks.forEach(track => {
                    if(track.subscriber.completed){
                        count_completed++;
                    }
                });
                if((count_completed >= tracks.data.data.length) && (user.category.id == 3)){
                    self.level_up = true;
                }
            });
        },
        async showModalTerms(){
            var self = this;

            if(self.level_up){
                $('#track-terms').modal('show');
            }
        },
        async downloadCertified() {
            var user = await $getUser;
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/png");
            myHeaders.append("Authorization", $headerAuth['Authorization']);

            var url = `${api_url}user/featured/${user.data.id}/certificate/download`;

            var myInit = { method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default' };

            var myRequest = new Request(url, myInit);

            fetch(url, myRequest)
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = url.replace(/^.*[\\\/]/, '');
                a.href = blobUrl;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        },
        async updateConsultant(){
            var self = this;
            var user = await $getUser;
            var account = await $getAccount;

            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours()+':'+today.getMinutes()+':'+today.getSeconds();

            await self.createCertificate(user.data.id);

            $('#track-terms').modal('hide');
            self.showCertificate();

            await axios.put(
                `${api_url}user/${user.data.id}`,
                {
                    'network' : self.level_up,
                    'certification_date' : date +' '+ time,
                    'groupID' : 3,
                },
                {headers: $headerAuth},
            ).then(function (data) {})
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao salvar progresso.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });
        }
    },
})
