import moment from 'moment'

/**
 * @license e-Calendar v0.9.3
 * (c) 2014-2016 - Jhonis de Souza
 * License: GNU
 */

(function ($) {

    var eCalendar = function (options, object) {
        // Initializing global variables
        var adDay = new Date().getDate();
        var adMonth = new Date().getMonth();
        var adYear = new Date().getFullYear();
        var dDay = adDay;
        var dMonth = adMonth;
        var dYear = adYear;
        var instance = object;
        let useFilteredEvents = false;
        let filteredDate = {d: null, m: null, y: null}
        var settings = $.extend({}, $.fn.eCalendar.defaults, options);
        var fsettings = $.extend({}, $.fn.eCalendar.defaults, options);
        const ogEvents = settings.events.slice(0)
        function originalEvents(){return ogEvents}


        function lpad(value, length, pad) {
            if (typeof pad == 'undefined') {
                pad = '0';
            }
            var p;
            for (var i = 0; i < length; i++) {
                p += pad;
            }
            return (p + value).slice(-length);
        }
        var toggleItem = function (e) {
            $(this).closest('.c-event-item').toggleClass('c-event-over');
            $(this).closest('.c-event-item').find(".description").toggleClass('content');
            var d = $(this).closest('.c-event-item').attr('data-event-day');
            $('div.c-event[data-event-day="' + d + '"]').toggleClass('c-event-over');
        };
        var nextMonth = function () {
            clearFilter();
            if (dMonth < 11) {
                dMonth++;
            } else {
                dMonth = 0;
                dYear++;
            }
            print();
        };
        var previousMonth = function () {
            clearFilter();
            if (dMonth > 0) {
                dMonth--;
            } else {
                dMonth = 11;
                dYear--;
            }
            print();
        };

        function loadEvents() {
            if (typeof settings.url != 'undefined' && settings.url != '') {
                $.ajax({url: settings.url,
                    async: false,
                    success: function (result) {
                        settings.events = result;
                    }
                });
            }
        }

        function loadFilteredEvents ({y, m, d}) {
            if (y === null &&  m === null && d === null) return loadEvents()

            fsettings.events = settings.events.filter( ev => {

                const startDate = ev.starts;
                const endDate = ev.ends;
                let iterDate = startDate.clone()
                let dates = []
                let dateInt = 0
                while (iterDate.isSameOrBefore(endDate)) {
                    dates.push(new moment(iterDate.toString()));
                    iterDate.add(1, "days");
                }

                dates.map( dt => {
                    if (dt.date() === d && dt.month() === m && dt.year() === y) {dateInt += 1};
                })
                return dateInt > 0;

            })

        }

        function setFilteredDate(dt) {
            useFilteredEvents = true;
            filteredDate = {d: dt.date(), m: dt.month(), y: dt.year()}
            print();
        }

        function clearFilter() {
            if(useFilteredEvents){
                settings.events = originalEvents();
                useFilteredEvents = false;
                filteredDate = {d: null, m: null, y: null}
                print()
            }
        }

        function print() {
            if(useFilteredEvents) loadFilteredEvents(filteredDate)
            else loadEvents(), fsettings.events = settings.events;
            var dWeekDayOfMonthStart = new Date(dYear, dMonth, 1).getDay() - settings.firstDayOfWeek;
            if (dWeekDayOfMonthStart < 0) {
                dWeekDayOfMonthStart = 6 - ((dWeekDayOfMonthStart + 1) * -1);
            }
            var dLastDayOfMonth = new Date(dYear, dMonth + 1, 0).getDate();
            var dLastDayOfPreviousMonth = new Date(dYear, dMonth + 1, 0).getDate() - dWeekDayOfMonthStart + 1;

            var cCalendar = $('<div/>').addClass('c-calendar');
            var cBody = $('<div/>').addClass('c-grid');
            var cEvents = $('<div/>').addClass('c-event-grid');
            var cEventsBody = $('<div/>').addClass('c-event-body');
            cEvents.append(cEventsBody);
            var cNext = $('<div/>').addClass('c-next');
            var cNextMobile = $('<div/>').addClass('c-next-mobile');
            var cMonth = $('<div/>').addClass('c-month c-grid-title');
            var cPrevious = $('<div/>').addClass('c-previous');
            var cPreviousMobile = $('<div/>').addClass('c-previous-mobile');

            cPreviousMobile.html(settings.textArrows.previous);
            cPrevious.html(settings.textArrows.previous);
            cMonth.html(settings.months[dMonth] + '<span>' + dYear + '</span>');
            cNext.html(settings.textArrows.next);
            cNextMobile.html(settings.textArrows.next);

            cPrevious.on('click', previousMonth);
            cPreviousMobile.on('click', previousMonth);
            cNext.on('click', nextMonth);
            cNextMobile.on('click', nextMonth);

            cCalendar.append(cPrevious);
            cCalendar.append(cBody);
            cCalendar.append(cNext);
            cCalendar.append(cPreviousMobile);
            cCalendar.append(cNextMobile);
            cBody.append(cMonth);

            $(cCalendar).find('.c-grid, .c-previous, .c-next').click(function() {
                clearFilter();
            });

            var dayOfWeek = settings.firstDayOfWeek;
            for (var i = 0; i < 7; i++) {
                if (dayOfWeek > 6) {
                     dayOfWeek = 0;
                }
                var cWeekDay = $('<div/>').addClass('c-week-day');
                cWeekDay.html(settings.weekDays[dayOfWeek]);
                cBody.append(cWeekDay);
                dayOfWeek++;
            }
            var day = 1;
            var dayOfNextMonth = 1;
            for (var i = 0; i < 42; i++) {
                var cDay = $('<div/>');
                if (i < dWeekDayOfMonthStart) {
                    cDay.addClass('c-day-previous-month c-pad-top');
                    cDay.html(dLastDayOfPreviousMonth++);
                } else if (day <= dLastDayOfMonth) {
                    cDay.addClass('c-day c-pad-top');
                    if (day == dDay && adMonth == dMonth && adYear == dYear) {
                        cDay.addClass('c-today');
                    }
                    for (var j = 0; j < settings.events.length; j++) {
                        var d = settings.events[j].datetime;
                        var isTraining = settings.events[j].type;
                        const startDate = settings.events[j].starts;
                        const endDate = settings.events[j].ends;
                        let iterDate = startDate.clone()
                        let dates = []

                        while (iterDate.isSameOrBefore(endDate)) {
                            dates.push(new moment(iterDate.toString()));
                            iterDate.add(1, "days");
                        }

                        dates.map( date => {
                            if(date.year() === dYear && date.month() === dMonth && date.date() === day)
                            {
                                cDay.addClass('c-event').attr('data-event-day', date.toString());
                                cDay.removeClass('shadow')
                                if(isTraining == 2) cDay.addClass('c-training');
                                if((useFilteredEvents) && ((date.year() === filteredDate.y && date.month() === filteredDate.m && date.date() === filteredDate.d))) cDay.addClass('shadow')

                                cDay.click(function(e){e.stopPropagation();setFilteredDate(date); return false})
                            }
                        })
                    }
                    cDay.html(day++);
                } else {
                    cDay.addClass('c-day-next-month c-pad-top');
                    cDay.html(dayOfNextMonth++);
                }
                cBody.append(cDay);
            }

            var eventList = $('<div/>').addClass('c-event-list');
            for (var i = 0; i < fsettings.events.length; i++) {
                var d = fsettings.events[i].datetime;

                const startDate = fsettings.events[i].starts;
                const endDate = fsettings.events[i].ends;
                var isTraining = fsettings.events[i].type;
                let text
                if(startDate.isSame(endDate, 'day'))
                    text = `Dia ${startDate.format('DD/MM')}`
                else
                    text = `Do dia ${startDate.format('DD/MM')} até o dia ${endDate.format('DD/MM')}`

                text += ` | ${startDate.format('HH:MM')} - ${endDate.format('HH:MM')}`;

                if (startDate.year() === dYear && startDate.month() === dMonth) {
                    var date = lpad(d.getDate(), 2) + '/' + lpad(d.getMonth() + 1, 2) + ' ' + lpad(d.getHours(), 2) + ':' + lpad(d.getMinutes(), 2);
                    var item = $('<div/>').addClass('c-event-item collapsible');
                    var title = $('<div/>').addClass('title').html(text + '  ' + '<br/>' + '<p class="c-event-local">' + fsettings.events[i].local + '</p>' + '<h2>' + fsettings.events[i].title + '</h2>');
                    var description = $('<div/>').addClass('description c-event-local').html(fsettings.events[i].description + '<br/>');
                    var hrTraining = "";
                    var titleTraining = "";
                    var descTraining = "";

                    if(isTraining == 2){
                        hrTraining = $('<hr>').addClass('hr-training');
                        titleTraining = $('<div/>').html('<h2 class="title-training"> <img src="img/dds_training.png" class="mr-2">' + fsettings.events[i].training.name + '</h2>');
                        descTraining = $('<div/>').addClass('description-training').html(fsettings.events[i].training.content + '<br/>');
                        item.addClass('c-event-item-training');
                    }
                    item.append('<div class="toggle-event"><div/>');
                    item.attr('data-event-day', d.getDate());
                    item.find('.toggle-event').on('click', toggleItem);
                    item.append(title);
                    if(isTraining == 2) {
                        item.append('<div class="training-event"><div/>')
                        $(description).append(hrTraining);
                        $(description).append(titleTraining);
                        description = $(description).append(descTraining);
                    }
                    else{
                        description = $(description).append('<br/>');
                    }
                    item.append(description);

                    // Add the url to the description if is set
                    if( fsettings.events[i].url !== undefined )
                    {
                        /**
                         * If the setting url_blank is set and is true, the target of the url
                         * will be "_blank"
                         */
                        type_url = fsettings.events[i].url_blank !== undefined &&
                        fsettings.events[i].url_blank === true ?
                                   '_blank':'';
                        description.wrap( '<a href="'+ fsettings.events[i].url +'" target="'+type_url+'" ></a>' );
                    }

                    eventList.append(item);
                }
            }
            $(instance).addClass('calendar');
            cCalendar.append(cEvents);
            cEventsBody.append(eventList);
            $(instance).html(cCalendar).append(cEvents);
        }

        return print();
    }

    $.fn.eCalendar = function (oInit) {
        return this.each(function () {
            return eCalendar(oInit, $(this));
        });
    };

}(jQuery));
