var SupportForms = {
    params:{
        form: null,
        submitSuccess: null,
        submitError: null,
        submitting: null,
        formBtn: null,
        formItems: null,
        user: null,
    },
    initForm: function(params){
        var self = this;
        self.params = params;

        self.getUserData();

        $(self.params.form).on('submit',function(e){
            e.preventDefault();
            self.formSubmit();
        });

        $('#attach').change(function(){
            var value = $(this).val();
            if(value){
                var startIndex = (value.indexOf('\\') >= 0 ? value.lastIndexOf('\\') : value.lastIndexOf('/'));
                var filename = value.substring(startIndex);
                if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                    filename = filename.substring(1);
                }

                $('#attach-name').text(filename.toUpperCase());
            }
        });
    },
    rmBorderFormItems: function(){
        this.params.formItems.forEach(element => {
            element.style.border = '';
        });
    },
    fnSubmitting: function(is_success, message, elementMessage){
        var self = this;
        $('.has-error').text('');
        self.params.submitting.style.display = 'none';

        self.rmBorderFormItems();

        if(is_success){
            $('#attach-name').text('');
            elementMessage.innerText = message;
            self.params.form.reset();
            self.getUserData();
        } else if (message.errors) {
            Object.entries(message.errors).forEach(error => {
                const elementDiv = document.createElement('div');
                const elementInput = document.querySelector('#form-support-data [name='+error[0]+']');
                elementInput.style.border = '2px solid #fb9595';
                elementDiv.innerText = error[1][0];
                $('.input-'+error[0]).html(elementDiv);
            });
        } else {
            elementMessage.innerText = 'Ops, houve algum erro ao enviar o formulário.';
        }

        elementMessage.style.display = 'block';

        setTimeout(function(){
            elementMessage.style.display = 'none';
            self.params.formBtn.style.opacity = '1';
            self.params.formBtn.removeAttribute("disabled");
        }, 3000);
    },
    formSubmit: function(){

        var self = this;
        self.params.formBtn.disabled = true;
        self.params.formBtn.style.opacity = '0.5';
        var formData = new FormData(self.params.form);
        self.params.submitting.style.display = 'block';

        $.ajax({
            method: 'POST',
            crossDomain: true,
            url: self.params.form.getAttribute('action'),
            contentType: false,
            processData: false,
            dataType: 'json',
            data: formData,
            success: function(result){
                self.fnSubmitting(true, result.message, self.params.submitSuccess);
            },
            error: function(error){
                self.fnSubmitting(false, error.responseJSON, self.params.submitError);
            }
        });
    },
    getUserData: async function(){
        let info = await $getUser;
        if(info){
            var name = $(this.params.form).find("input[name='name']");
            var email = $(this.params.form).find("input[name='email']");
            $(name).val(info.data.name);
            $(email).val(info.data.email);
            $(name).attr('readonly',true);
            $(email).attr('readonly',true);
        }
    }

}


if (SupportForms && SupportForms.initForm) {
    SupportForms.initForm({
        form: document.getElementById("form-support-data"),
        submitSuccess: document.querySelector("#form-support-data .submit-success"),
        submitError: document.querySelector("#form-support-data .submit-error"),
        submitting: document.querySelector("#form-support-data .submitting"),
        formBtn: document.querySelector("#form-support-data .btn-form"),
        formItems: document.querySelectorAll("#form-support-data .form-item")
    });
}

