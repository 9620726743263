let profileImg = null;
$(document).ready(async function () {

    let info = await $getUser;

    let firstName = info.data.name
        .split(" ")
        .slice(0, 1)
        .join(" ");
    let lastName = info.data.name
        .split(" ")
        .slice(1)
        .join(" ");

    let image =
        '<div class="imagePreview" style="background-image: url(' +
        info.data.avatar +
        ')"> </div>';
    let imagePoints =
        '<div style="background-image: url(' +
        info.data.avatar +
        ')"> </div>';
    let avatarHeader = '<img src="' + info.data.avatar + '" alt="usuário">';
    let tooltip =
        "<span>" + firstName + " <br> " + info.data.email + "</span>";

    /* Perfil Dados*/
    let name = info.data.name;
    let email = info.data.email;
    let cpf = info.data.cpf;

    /* Avatar Header */
    $("#avatarHeader").append(avatarHeader);
    $("#avatarHeader").append(tooltip);

    $("#header-image").append(image);
    $("#points-image").append(imagePoints);
    $("#menu-image").append(imagePoints);
    $("#profile-name").append(name);
    $(".profile-name").append(name);
    $(".menu-name").append(name);
    $(".moves-name").append(name);

    $('.name-user').show();
    $('.tab-category-favorite').show();

    if (!info || !info.data.avatar) {
        $(".profile-letters").append(
            firstName.charAt(0),
            lastName.charAt(0)
        );        
        $('.name-user').hide();
        $('.tab-category-favorite').hide();
    } else {
        const uploadAvatarMessageBox = document.getElementById('upload-avatar-message');

        if(uploadAvatarMessageBox) {
            uploadAvatarMessageBox.style.display = 'none';
        }
    }
    

    $("#menu-cpf").append(cpf);

    $('.user-login').addClass('img-perfil');
    $('.user-login a').attr('href', '#javascript:;');
    $('.user-login .img-perfil-header-hide, .user-login .login-text').hide();

    // MENU //
    var slidebar_width = 800; //slidebar width + padding size
    var slide_bar = $(".side-menu-wrapper"); //slidebar
    var slide_open_btn = $(".img-perfil"); //slidebar close btn
    var slide_close_btn = $(".menu-close"); //slidebar close btn
    var slide_menu_items = $(".menu-items a"); //slidebar menu items
    var slide_menu_item_logout = $(".menu-items a#logout"); //slidebar menu item logout
    var overlay = $(".side-menu-overlay"); //slidebar close btn

    function close_menu() {
        slide_bar.css({"display": "none"}); //change to "right" for right positioned menu
        overlay.css({"opacity": "0", "width": "0"});
    }

    slide_open_btn.click(function (e) {
        e.preventDefault();
        slide_bar.css({"display": "block"});
        slide_bar.css({"z-index": "99999"});
        overlay.css({"opacity": "1", "width": "100%", "z-index": "9999"});
        $("#menu").focus();
    });
    slide_close_btn.click(function (e) {
        e.preventDefault();
        close_menu();
    });
    slide_menu_items.click(function () {
        close_menu();
    });
    slide_menu_item_logout.on('focusout', function () {
        slide_close_btn.focus();
    });
});

document.addEventListener('keydown', function(event) {
    if (event.shiftKey && event.key === 'Tab') {
        var focusedElement = document.activeElement;
        if (focusedElement && focusedElement.classList.contains('menu-close')) {
            var logoutElement = document.getElementById('logout');
            if (logoutElement) {
                logoutElement.focus();
                event.preventDefault();
            }
        }
    }
});