export default {
    arrows: true,
    centerMode: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '10px',
    responsive: [
        {
        breakpoint: 991,
        settings: {
            arrows: false,
            centerMode: false,
            infinite: true,
            slidesToShow: 1,
        }
        },
        {
        breakpoint: 600,
        settings: {
            arrows: false,
            centerMode: false,
            infinite: false,
            slidesToShow: 1,
            initialSlide: 0,
        }
        }
    ]
}
