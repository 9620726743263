if ($('#login').length) {
    require("./auth/login.js");
    require('./auth/steps-login.js');
}

if ($('#banner-container').length) require("./banners/banners");
if ($('.menu-feedback').length) require("./user/userFeedback");
if ($('#profile').length) require("./user/userProfile.js");
if ($('#notifications').length) require("./notification/notifications.js");
if ($('.user-account').length) require("./user/userAccount");
if ($('#extracts').length) require("./moves/extract");
if ($('#histories').length) require("./moves/history");
if ($('#product-list').length) require("./awards/products.js");
if ($('#product-detail').length) require("./awards/productDetail.js");
if ($('#news-list').length) require("./news/news.js");
if ($('#news-detail').length) require("./news/news-detail.js");
if ($('#news-container').length) require("./news/lasted-news-carousel.js");
if ($('#testimonials-container').length) require("./testimonials/testimonialsApproved");
if ($('#materials-list').length) require("./materials/materials.js");
if ($('#trainings-list').length) require("./materials/trainings.js");
if ($('#calendar').length) require("./events/events.js");
if ($('#contact-us').length) require("./contact/contact.js");
if ($('#request-control').length) require("./orders/rescueProducts.js");
if ($('#request-confirmation').length) require("./orders/rescueConfirmation.js");
if ($('#testimonials').length) require("./testimonials/testimonialsApproved.js");
if ($('#highlights-consultants').length) require("./highlights-consultants/paginate.js");
if ($('#my-learning').length) require("./trainings/myLearning.js");
if ($('#training-list').length) require("./trainings/training.js");
if ($('#training-detail').length) require("./trainings/trainingDetail.js");
if ($('#track-detail').length) require("./trainings/trackDetail.js");
if ($('.support-content').length) require("./support/support.js");
