new Vue({
    el: '#consultant-specialist',
    data: {
        model: {}
    },
    created: function () {
        this.getAccount();
    },
    mounted: function () {
    },
    methods: {
        async getAccount(page = 1) {
            var self = this;
            var account = await $getAccount;
            var consultants = [];
            consultants.push({type:'gerente', title: 'Gerente', data: account.consultants});
            consultants.push({type:'perifericos', title: 'Periféricos', data: account.consultants.peripheral});
            consultants.push({type:'software', title: 'Software', data: account.consultants.software});
            consultants.push({type:'enterprise', title: 'Enterprise', data: account.consultants.tsr});
            consultants.push({type:'servicos', title: 'Serviços', data: account.consultants.ssr});
            consultants = consultants.filter((consultant) => consultant.data != null);
            consultants.forEach(consultant => {
                if (consultant.data.phone)
                    consultant.data.phone_min = consultant.data.phone.replace(/\D/g,"");

                if (consultant.data.whatsapp)
                    consultant.data.whatsapp_min = consultant.data.whatsapp.replace(/\D/g,"");
            });
            self.model = consultants;
        },
        closeConsultants(){
            $('.main-menu-top a.navbar-brand').focus();
        },
        closeConsultantsOnHitEnter() {
            this.closeConsultants();
        }
    }    
});

