import states from "../variables/statesAndCities";

new Vue({
    el: "#highlights-consultants",
    data: {
        model: {},
        form: {
            name: "",
            state: "",
            city: ""
        },
        dropdown: {
            state: {
                search: "",
                show: false
            },
            city: {
                search: "",
                show: false
            }
        },
        states: {},
        cities: {},
        seeMoreInfo: false,
        modal: {
            show: false,
            consultant: {}
        }
    },
    created: function() {
        this.getItems();
        this.getStates();

        const currentUrl = window.location.href;
        var consultant_id = currentUrl.split("-id-")[1] || false;
        if (consultant_id) this.getConsultantById(consultant_id);
    },
    mounted: function() {},
    methods: {
        async getItems(page = 1) {
            var self = this;

            let nameSearch =
                self.form.name != "" ? `&name=${self.form.name}` : "";
            let stateSearch =
                self.form.state != "" ? `&state=${self.form.state}` : "";
            let citySearch =
                self.form.city != "" ? `&city=${self.form.city}` : "";

            let response = await axios.get(
                `${api_url}user/featured?page=${page}&per_page=12${nameSearch}${stateSearch}${citySearch}`,
                { headers: $headerAuth }
            );

            let consultants = response.data;

            consultants.data.forEach(consultant => {
                const array_name = consultant.name
                    .split(" ")
                    .filter(el => el != "");
                consultant.firstName = array_name[0];
                consultant.lastName = array_name[array_name.length - 1];
                consultant.letters = [
                    consultant.firstName.charAt(0),
                    consultant.lastName.charAt(0)
                ];
                consultant.tel_phone = consultant.tel1.replace(/\D/g, "");
            });

            self.model = consultants;

            $(".tooltip-copy").click(function() {
                $(".tooltiptext").addClass("show");
                setTimeout(function() {
                    $(".tooltiptext").removeClass("show");
                }, 3000);
            });
        },
        async getItemsByPage(page) {
            await this.getItems(page);
            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $("#highlights-consultants").offset().top - 100
                },
                0
            );
        },
        getStates() {
            var self = this;
            if (self.dropdown.state.search != "") {
                self.states = states.filter(state => {
                    return (
                        state.sigla.indexOf(
                            self.dropdown.state.search.toUpperCase()
                        ) != -1 ||
                        state.nome
                            .toUpperCase()
                            .indexOf(
                                self.dropdown.state.search.toUpperCase()
                            ) != -1
                    );
                });
            } else {
                self.states = states;
            }
        },
        getCities() {
            var self = this;
            const stateSelected = states.filter(state => {
                return state.sigla == self.form.state;
            });

            const cities = stateSelected.length ? stateSelected[0].cidades : {};

            if (self.dropdown.city.search != "") {
                self.cities = cities.filter(city => {
                    return (
                        city
                            .toUpperCase()
                            .indexOf(self.dropdown.city.search.toUpperCase()) !=
                        -1
                    );
                });
            } else {
                self.cities = cities;
            }
        },
        openDropdown(name) {
            var self = this;
            let hasShow = self.dropdown[name].show;

            for (var key in self.dropdown) {
                self.dropdown[key].show = false;
            }

            if (!hasShow) self.dropdown[name].show = true;
        },
        setDropdown(name, value) {
            var self = this;
            self.form[name] = value;
            for (var key in self.dropdown) {
                self.dropdown[key].show = false;
            }

            if (name == "state") {
                self.getCities();
                self.form.city = "";
            }

            self.getItems();
        },
        clearForm(array) {
            var self = this;
            array.forEach(key => {
                if (self.dropdown[key]) self.dropdown[key].search = "";
                self.form[key] = "";
            });
            self.getItems();
            self.getStates();
            self.getCities();
        },
        showMoreInfo() {
            var self = this;
            self.seeMoreInfo = !self.seeMoreInfo;
        },
        openConsultantModal(id) {
            var self = this;
            self.modal.consultant = self.model.data.filter(consultant => {
                return id == consultant.id;
            })[0];
            const _id = self.modal.consultant.id;
            const _slug = self.slugfy(self.modal.consultant.name);
            window.history.pushState(
                [],
                `Dell Expert Network`,
                `/consultores-elite-certificados/${_slug}-id-${_id}`
            );
            self.modal.show = true;
        },
        slugfy(text) {
            return text
                .toString() // Cast to string
                .toLowerCase() // Convert the string to lowercase letters
                .normalize("NFD") // The normalize() method returns the Unicode Normalization Form of a given string.
                .trim() // Remove whitespace from both sides of a string
                .replace(/\s+/g, "-") // Replace spaces with -
                .replace(/[^\w\-]+/g, "") // Remove all non-word chars
                .replace(/\-\-+/g, "-"); // Replace multiple - with single -
        },
        closeModal() {
            var self = this;
            self.modal.show = false;
            window.history.pushState(
                [],
                `Dell Expert Network`,
                `/consultores-elite-certificados`
            );
        },
        copyUrl() {
            var input = document.createElement("input");
            input.setAttribute("value", window.location.href);
            document.body.appendChild(input);
            input.select();
            document.execCommand("copy");
            document.body.removeChild(input);
        },
        async getConsultantById(id) {
            var self = this;
            let response = await axios.get(`${api_url}user/featured/${id}`, {
                headers: $headerAuth
            });
            self.modal.consultant = response.data;
            const array_name = self.modal.consultant.name
                .split(" ")
                .filter(el => el != "");
            self.modal.consultant.firstName = array_name[0];
            self.modal.consultant.lastName = array_name[array_name.length - 1];
            self.modal.consultant.letters = [
                self.modal.consultant.firstName.charAt(0),
                self.modal.consultant.lastName.charAt(0)
            ];
            self.modal.consultant.tel_phone = self.modal.consultant.tel1.replace(
                /\D/g,
                ""
            );
            self.modal.show = true;
        }
    }
});
