import moment from "moment";

$(document).ready(async function () {
    let account = await $getAccount
    $.ajax({
        crossDomain: true,
        url: api_url + "event/active" + (account ? "?restrict=" + account.category.id : ""),
        contentType: "application/json",
        dataType: 'json',
        success: function (result) {
            var events = [];
            result.forEach(event => {

                if (event === undefined || event === null || event.date_hours === null) return;

                const starts = new moment(event.date_hours[0].start);
                const ends = new moment(event.date_hours[0].end);
                let type = 1;

                if ((event.training) && (account)) {
                    type = 2;
                }

                events.push({
                    type: type,
                    title: event.title,
                    training: event.training,
                    local: event.place,
                    description: event.content,
                    starts,
                    ends,
                    datetime: new Date(
                        event.date
                            .split(`/`)
                            .reverse()
                            .join(`-`)
                    )
                });
            });

            var iconBack =
                '<img src="img/icon-back.png" height="25" width="31" alt="left arrow">';
            var iconNext =
                '<img src="img/icon-next.png" height="25" width="31" alt="right arrow">';

            $("#calendar").eCalendar({
                weekDays: ["D", "S", "T", "Q", "Q", "S", "S"],
                months: [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro"
                ],
                textArrows: { previous: iconBack, next: iconNext },
                /*eventTitle: 'Events',*/
                url: "",
                firstDayOfWeek: 1,
                events: events
            });
        }
    });
});
