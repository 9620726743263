$(".btn-send-feedback").on("click", function (e) {
    e.preventDefault();
    let data = {message: $("#feedback").val()}
    var settings = {
        url: api_url + "notification/user/feedback",
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + localStorage.token
        },
        dataType: "json",
        data
    };

    $.ajax(settings)
        .done(function (response) {
             $("#send-feedback-success").show();
             $("#send-feedback").hide();
        })
        .fail(function () {
        });
});

$(".btn-send-feedback-mobile").on("click", function (e) {
    e.preventDefault();
    let data = {message: $("#feedback-mobile").val()}
    var settings = {
        url: api_url + "notification/user/feedback",
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + localStorage.token
        },
        dataType: "json",
        data
    };

    $.ajax(settings)
        .done(function (response) {
             $("#send-feedback-mobile-success").show();
             $("#send-feedback-mobile").hide();
        })
        .fail(function () {
        });
});
