const rescueProductsFiltered = $RESCUE_PRODUCTS.filter(el => el != null);

new Vue({
    el: '#request-control',
    data: {
        credits: 0,
        rescueProducts: {},
        rescueProductsPoints : rescueProductsFiltered.reduce((total, purchase) => total + (purchase.points * purchase.qty), 0),
        rescueProductsQty : rescueProductsFiltered.reduce((total, purchase) => total + purchase.qty, 0),
    },
    created: function(){
        this.getRescueProducts();
        this.getAccount();
    },
    mounted: function(){
        //
    },
    methods:{
        getRescueProducts(){
            var self = this;
            self.rescueProducts = rescueProductsFiltered;
        },
        changeQty(moreOrLess, purchase_id){
            $RESCUE_PRODUCTS[purchase_id].qty = moreOrLess ? ++$RESCUE_PRODUCTS[purchase_id].qty : ($RESCUE_PRODUCTS[purchase_id].qty < 2 ? 1 : --$RESCUE_PRODUCTS[purchase_id].qty);
            var filtered = $RESCUE_PRODUCTS.filter(el => el != null);
            localStorage.rescueProducts = JSON.stringify(filtered);
            this.rescueProducts = filtered;
            this.rescueProductsPoints = filtered.reduce((total, purchase) => total + (purchase.points * purchase.qty), 0);
            this.rescueProductsQty = filtered.reduce((total, purchase) => total + purchase.qty, 0);
        },
        remove(product_id){
            var self = this;
            self.rescueProducts = self.rescueProducts.filter(purchase => (purchase.id != product_id));
            self.rescueProductsPoints = self.rescueProducts.reduce((total, purchase) => total + (purchase.points * purchase.qty), 0);
            self.rescueProductsQty = self.rescueProducts.reduce((total, purchase) => total + purchase.qty, 0);
            localStorage.rescueProducts = JSON.stringify(self.rescueProducts);
            updateKeysRescueProducts(self.rescueProducts);
        },
        async getAccount(){
            var self = this;
            let response = await $getAccount;
            self.credits = response.credits;
        },
    }
});
