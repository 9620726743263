let profileImg = null;

new Vue({
    el: "#profile",
    data: {
        model: {},
        profile: {},
        editMode: 0,
        message: '',
        profileImgToUpload: {
            target: false,
            file: false,
            change: false,
        },
        photoTaken: false,
        mediaStream: '',
        videoBox: {},
        photoCountdown: 5,
        showCountdown: false,
        avatarModal: {},
        overlay: {},
        takePicture: false,
        avatarLoaded: false,
        avatarUploadInitialized: false,
    },
    created: async function () {
        this.get();
    },
    mounted: async function () {
        const { search } = window.location;
        const params = new URLSearchParams(search);

        if (params.get('editMode')) {
            document.addEventListener('DOMContentLoaded', () => {
                this.openModalTakePicOrUpload();
            });
        }

        this.videoBox = document.getElementById('avatar-video');
        this.canvasSnapShot = document.getElementById('canvas');
        this.avatarModal = document.getElementById('avatar-modal');
    },
    methods: {
        async get() {
            let response = await $getUser;
            this.model = { ...response.data };
            this.profile = { ...response.data };
        },
        async post() {
            var self = this;
            let form = self.getFormData(self.$refs.form);
            form.view_info = self.model.view_info ? 'on' : 'off';
            if (self.profileImgToUpload.change) await self.uploadAvatar()
            await axios
                .put(
                    api_url + "user/" + localStorage.getItem("user_id"),
                    form,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.token,
                            "Content-Type": "application/json"
                        }
                    }
                ).then(function (response) {
                    self.model = { ...response.data };
                    self.editMode = 0;
                    $.toast({
                        text: "Dados atualizados com sucesso!",
                        position: 'bottom-right',
                        textColor: 'white',
                        bgColor: '#6EA204'
                    })
                    setTimeout(function () { self.message = '' }, 3000);
                    self.profileImgToUpload.change = false;
                })
                .catch(function (erros) {
                    $.toast({
                        text: "Erro ao salvar dados",
                        position: 'bottom-right',
                        textColor: 'white',
                        bgColor: '#CE1126'
                    })
                    setTimeout(function () { self.message = '' }, 3000);
                });
        },
        sendAvatar(e) {
            var self = this;
            const file = e.target.files[0]
            var reader = new FileReader();
            if (e.target.files && e.target.files[0]) {
                self.profileImgToUpload = { target: e.target.result, file, change: true };
                reader.onload = function (e) {
                    $(".imagePreview").css(
                        "background-image",
                        "url(" + e.target.result + ")"
                    );
                    $(".imagePreview").hide();
                    $(".imagePreview").fadeIn(650);
                }
                reader.readAsDataURL(e.target.files[0]);
                this.avatarLoaded = true;
            }
        },
        async uploadAvatar() {
            const form = new FormData();
            form.append("avatar", this.profileImgToUpload.file);
            await axios.post(
                api_url +
                "user/image/" +
                localStorage.getItem("user_id"),
                form,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.token,
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
        },
        getFormData(form) {
            var newForm = {};
            for (const field of form) {
                if (field.name) {
                    newForm[field.name] = field.value;
                }
            }
            return newForm;
        },
        toggleEdit() {
            this.editMode = 1;
        },
        openModalTakePicOrUpload() {
            this.addOverlay();
            this.avatarModal.style.display = "block";
        },
        addOverlay() {
            let overlay = document.createElement('div');

            this.overlay = overlay;
            this.overlay.style.position = 'fixed';
            this.overlay.style.top = '0';
            this.overlay.style.left = '0';
            this.overlay.style.width = '100%';
            this.overlay.style.height = '100%';
            this.overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            this.overlay.style.zIndex = '9998';
            this.overlay.setAttribute("id", "overlay");

            document.body.appendChild(this.overlay);
        },
        removeOverlay() {
            this.overlay.remove();
        },
        startCameraStream() {
            this.videoBox.style.display = 'block';

            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    this.videoBox.srcObject = stream;
                    this.mediaStream = stream;
                })
                .catch((err) => {
                    console.error("Error accessing camera: " + err);
                });
        },
        stopCameraStream() {
            if (this.mediaStream != '') {
                this.mediaStream.getTracks().forEach(track => track.stop());
                this.videoBox.srcObject = null;
                this.mediaStream = '';
            }
        },
        startPhotoCountdown() {
            if (!this.showCountdown) {
                this.showCountdown = true;

                const photoCountdownInterval = setInterval(() => {
                    this.photoCountdown--;
                    if (this.photoCountdown === 0) {
                        clearInterval(photoCountdownInterval)
                        this.photoCountdown = 5;
                        this.capturePhoto();
                        this.showCountdown = false;
                    }
                }, 1000);
            }
        },
        capturePhoto() {
            setTimeout(() => {
                this.canvasSnapShot.style.opacity = 0;
                this.photoTaken = true;
                this.canvasSnapShot.width = this.videoBox.videoWidth;
                this.canvasSnapShot.height = this.videoBox.videoHeight;
                this.canvasSnapShot.getContext('2d').drawImage(this.videoBox, 0, 0, this.videoBox.videoWidth, this.videoBox.videoHeight);
            }, 200);

            setTimeout(() => {
                this.canvasSnapShot.style.opacity = 1;
            }, 400);
        },
        resetPhotoState() {
            this.avatarModal.style.display = "none";
            this.stopCameraStream();
            this.photoTaken = false;
            this.removeOverlay();
            this.takePicture = false;
            this.avatarLoaded = false;
            this.avatarUploadInitialized = false;
        },
        showAvatarUploadMessage() {
            $.toast({
                text: "Avatar atualizado com sucesso!",
                position: 'bottom-right',
                textColor: 'white',
                bgColor: '#6EA204'
            })
            setTimeout(function () { this.message = '' }, 4000);
        },
        uploadPhoto() {
            if (!this.avatarUploadInitialized) {
                this.avatarUploadInitialized = true;
                this.canvasSnapShot.toBlob((blob) => {
                    const formData = new FormData();
                    const avatarUrl = URL.createObjectURL(blob);

                    formData.append('avatar', blob);

                    $(".imagePreview").each(function () {
                        $(this).css(
                            "background-image",
                            `url(${avatarUrl})`,
                        ).hide().fadeIn(650);
                    });

                    fetch(api_url + "user/image/" + localStorage.getItem("user_id"), {
                        method: 'POST',
                        body: formData,
                        headers: {
                            Authorization: "Bearer " + localStorage.token
                        }
                    })
                        .then(data => {
                            this.resetPhotoState();
                            this.showAvatarUploadMessage();
                            // console.log('Success:', data);
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                }, 'image/png');
            }
        },
        finishLoadAvatar() {
            this.resetPhotoState();
            this.post();
        },
    }
});
