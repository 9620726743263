Vue.component('lasted-trainings', () => import('./lasted-trainings.vue'));
Vue.component('sugest-avatar-update', () => import('./sugest-avatar-update.vue'));
Vue.component('training-avatar-update', () => import('./training-avatar-update.vue'));

const hasSlug = {
    status: $('#training-list').data('category') !== undefined ? true : false,
    category: $('#training-list').data('category'),
    subcategory: $('#training-list').data('subcategory'),
};

var load_category = 0;

export default new Vue({
    el: '#div-training-list',
    data: {
        typeSelected: {},
        types: {},
        type_all: [
            {
                name: 'Todos os treinamentos',
                slug: 'all',
                subcategories: [],
            }
        ],
        trainings: {},
        currentType: '',
        currentCategory: '',
        currentIdCategory: '',
        currentSubcategory: '',
        paginate: {},
        isMobile: $(window).width() <= 767 ? true : false,
        subcategoryTemp: [],
        slickMobile: {},
        loading: false,
        registered: false,
        title: null,
        input: {
            name: ''
        },
        tracksCompleted: false,
        user: {},
    },
    created: function () {
        this.getTypes();
    },
    updated: function () {
        var self = this
        self.$nextTick(function () {
            setTimeout(async function () {
                const listItem = $('.categoryTrainingMobile').not('.slick-initialized').slick({
                    dots: false,
                    arrows: false,
                    infinite: true,
                    speed: 300,
                    centerMode: true,
                    variableWidth: true,
                    focusOnSelect: true,
                });

                const listItem2 = $('.subcategoryTrainingMobile').not('.slick-initialized').slick({
                    dots: false,
                    arrows: false,
                    infinite: true,
                    speed: 300,
                    centerMode: true,
                    variableWidth: true,
                    focusOnSelect: true,
                });

                listItem.on('afterChange', async function (event, slick, currentSlide, nextSlide) {
                    const elementSlick = $(slick.$slides[currentSlide])[0];
                    const category = elementSlick.dataset.category;
                    self.currentIdCategory = elementSlick.dataset.slickIndex;
                    self.getTrainings(self.currentType, category, null),
                        self.currentCategory = category
                });

                listItem2.on('afterChange', async function (event, slick, currentSlide, nextSlide) {
                    const elementSlick = $(slick.$slides[currentSlide])[0];
                    const subcategory = (elementSlick) ? elementSlick.dataset.subcategory : null;
                    self.getTrainings(self.currentType, self.currentCategory, subcategory),
                        self.currentSubcategory = subcategory
                });

                const slideIndex = listItem.find('li[data-category=' + self.currentCategory + ']').index()
                listItem.slick('slickGoTo', parseInt(slideIndex - 2))

                const slideIndex2 = listItem2.find('li[data-category=' + self.currentCategory + ']').index()
                listItem2.slick('slickGoTo', parseInt(slideIndex2 - 2))

                $('.subcategoryTrainingMobile').find('.slick-track').css('transform', 'translate3d(0px, 0px, 0px)');

            }, 1000);
        })
    },
    async mounted() {
        let response = await $getUser;
        this.user = { ...response.data };

        $('#sugestAvatarUpload, #avatarUpload').on('show.bs.modal', function () {
            $('body').css('overflow', 'hidden');
        });

        $('#sugestAvatarUpload, #avatarUpload').on('hide.bs.modal', function () {
            $('body').css('overflow', 'visible');
        });
    },
    watch: {
        async tracksCompleted(newCompletedTracksState) {
            if (newCompletedTracksState) {
                if (localStorage.getItem('uploadedAvatarForEliteCertificate') == null) {
                    setTimeout(() => {
                        $('#sugestAvatarUpload').modal('show');
                    }, 500);
                }
            }
        }
    },
    methods: {
        async getTypes() {
            this.currentType = (this.currentType) ? this.currentType : 'treinamentos';
            this.title = "Treinamentos";

            let response = await axios.get(
                `${api_url}training/categories?sort=order,id`,
                { headers: $headerAuth }
            );

            this.types = this.type_all;
            this.types = this.types.concat(response.data);
            this.types.forEach(type => {
                if (!load_category) {
                    let subcategory = (type.subcategories.length > 0) ? type.subcategories[0].slug : null
                    this.getTrainings(this.currentType, type.slug, subcategory);
                    this.currentCategory = type.slug;
                    this.currentSubcategory = subcategory;
                    this.currentIdCategory = type.id;
                    load_category = 1;
                }
                this.subcategoryTemp = (type.subcategories.length > 0) ? this.subcategoryTemp.concat(type.subcategories) : this.subcategoryTemp;
            });
        },
        async getTrainings(type, category, subcategory, page = 1) {
            var self = this;
            let hasConsultantCompletedAllTracks = true;

            if (self.loading) return;

            self.loading = true;
            self.currentType = type;
            self.title = (type == 'trilhas') ? "Trilhas" : "Treinamentos";
            let response = await axios.get(
                `${api_url}training${type == 'trilhas' ? '/track' : ''}?active=1&per_page=9${category != 'all' ? '&slugcategory=' + category : ''}${subcategory ? '&slugsubcategory=' + subcategory : ''}&page=${page}&sort=id`,
                { headers: $headerAuth }
            );

            let trainings = response.data.data;
            trainings.forEach(training => {
                let c = (training.category) ? training.category.slug : '';
                let s = (training.subcategory) ? '/' + training.subcategory.slug : '';
                if (type == 'trilhas') {
                    training.url = '/trilhas/' + training.slug;
                    hasConsultantCompletedAllTracks = hasConsultantCompletedAllTracks && training.subscriber.completed;
                } else {
                    training.url = '/treinamentos/' + c + '/' + training.slug;
                }

                if (training.subscriber) training.subscriber.evaluation = (training.subscriber.evaluation) ? ((training.subscriber.evaluation.length > 0) ? training.subscriber.evaluation : false) : false;
            });

            if (type == 'trilhas') {
                self.tracksCompleted = hasConsultantCompletedAllTracks;
            }

            self.trainings = trainings;
            self.currentCategory = category;
            self.currentSubategory = subcategory;
            self.paginate = response.data;
            self.typeSelected = self.getType(category);

            setTimeout(function () {
                self.loading = false
            }, 500);
        },
        async getSubcategories(id) {
            if (id > 0) {
                var self = this;
                await axios.get(
                    `${api_url}training/categories/${id}`,
                    { headers: $headerAuth }
                )
                    .then(function (response) {
                        self.subcategoryTemp = response.data.subcategories;
                    });
            }
        },
        getTrainingsByPage(page) {
            this.getTrainings(this.currentType, this.currentCategory, this.currentSubcategory, page);
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#training-list").offset().top - 100
            }, 0);
        },
        changeType(type, category, subcategory) {
            var self = this;
            self.input.name = "";
            self.getTrainings(type, category, subcategory)
        },
        getType(slug) {
            return this.types.filter((type) => type.slug === slug)[0]
        },
        async search(category, subcategory, page = 1) {
            var self = this;

            self.title = (self.currentType == 'trilhas') ? "Trilhas" : "Treinamentos";
            let response = await axios.get(
                `${api_url}training${self.currentType == 'trilhas' ? '/track' : ''}?page=${page}${((category) && (category != 'all')) ? '&slugcategory=' + category : ''}${subcategory ? '&slugsubcategory=' + subcategory : ''}&per_page=9&active=1&name=${self.input.name}`,
                { headers: $headerAuth }
            );

            let trainings = response.data.data;
            trainings.forEach(training => {
                let c = (training.category) ? training.category.slug : '';
                let s = (training.subcategory) ? '/' + training.subcategory.slug : '';
                training.content = training.content.cutWord();
                if (self.currentType == 'trilhas') {
                    training.url = '/trilhas/' + training.slug;
                }
                else {
                    training.url = '/treinamentos/' + c + '/' + training.slug;
                }
            });

            self.trainings = trainings;
            self.currentCategory = category;
            self.currentSubategory = subcategory;
            self.paginate = response.data;
            self.typeSelected = self.getType(category);

            setTimeout(function () {
                self.loading = false
            }, 500);
        }
    }
});
