(function ($, w) {
    const hideAll = () => {
        $("#login-access").hide();
        $("#login-first-access").hide();
        $("#login-register").hide();
        $("#login-menu").hide();
        $(".form-view-loader").hide();
        $(".form-view-error").hide();
        //Cadastro
        $(".form-view-01").hide();
        $(".form-view-02").hide();
        $(".form-view-03").hide();
        $(".form-view-04").hide();
        $(".form-view-05").hide();
        $(".form-view-06").hide();
        $(".form-view-07").hide();
        $(".form-view-08").hide();
        $(".form-view-09").hide();
        $(".form-view-10").hide();
        $(".form-view-11").hide();
        $(".form-view-12").hide();
        $(".form-view-13").hide();
        $(".form-view-14").hide();
        $(".form-view-15").hide();
        $(".termos-condicoes").hide();
        $(".privacidade").hide();
        $(".form-view-18").hide();

        $(".steps-view").hide();
        $(".steps-circle").removeClass("circle-active");
        //Esqueci minha senha
        $("#login-forgot").hide();
        $(".change-password").hide();
    };

    const showLogin = () => {
        hideAll();
        $("#login-access").show();
        $(".form-forgot-01").show();
        $("#txtLogin").html("Faça seu login para visualizar o seu perfil do programa, informações de conta, extrato de créditos, resgate de prêmios, e mais!");
    };

    const showForgot = () => {
        hideAll();
        $("#login-forgot").show();
        $("#txtLogin").html("Adicione nos campos ao lado o seu e-mail e CPF cadastrados no programa e receba por e-mail o código para recuperar a sua senha.");
    };

    const showMain = () => {
        hideAll();
        $("#login-menu").show();
        $(".login-intro").show();
        $("#txtLogin").html("Faça seu login para visualizar o seu perfil do programa, informações de conta, extrato de créditos, resgate de prêmios, e mais!");
    };

    $(".back-to-menu").click(function () {
        showMain();
        $(".login").val("");
    });

    const registerShowForm = form => {
        hideAll();
        $(".login-intro").show();
        $("#txtLogin").html("Você está quase lá. Faça parte do programa de relacionamento da Dell que oferece benefícios exclusivos para profissionais de TI!");
        switch (form) {
            case "form-01":
                $("#login-register").show();
                $(".form-view-01").show();

                $(".steps-view").show();
                $(".step-circle-01").addClass("circle-active");
                break;
            case "form-02":
                $("#login-register").show();
                $(".form-view-02").show();

                $(".steps-view").show();
                $(".step-circle-02").addClass("circle-active");
                break;
            case "form-03":
                $("#login-register").show();
                $(".form-view-03").show();

                $(".steps-view").show();
                $(".step-circle-03").addClass("circle-active");
                break;
            case "form-04":
                $("#login-register").show();
                $(".form-view-04").show();

                $(".steps-view").show();
                $(".step-circle-04").addClass("circle-active");
                break;
            case "form-05":
                $("#login-register").show();
                $(".form-view-05").show();

                $(".steps-view").show();
                $(".step-circle-05").addClass("circle-active");
                break;
            case "form-06":
                $("#login-register").show();
                $(".form-view-06").show();

                $(".steps-view").show();
                $(".step-circle-06").addClass("circle-active");
                break;
            case "form-07":
                searchCep($("#cep").val());
                $("#login-register").show();
                $(".form-view-07").show();

                $(".steps-view").show();
                $(".step-circle-07").addClass("circle-active");
                break;
            case "form-08":
                $("#login-register").show();
                $(".form-view-08").show();

                $(".steps-view").show();
                $(".step-circle-08").addClass("circle-active");
                break;
            case "form-09":
                $("#login-register").show();
                $(".form-view-09").show();

                $(".steps-view").show();
                $(".step-circle-09").addClass("circle-active");
                break;
            case "form-10":
                $("#login-register").show();
                $(".form-view-10").show();

                $(".steps-view").show();
                $(".step-circle-10").addClass("circle-active");
                break;
            case "form-11":
                $("#login-register").show();
                $(".form-view-11").show();

                $(".steps-view").show();
                $(".step-circle-11").addClass("circle-active");
                break;
            case "form-12":
                $("#login-register").show();
                $(".form-view-12").show();

                $(".steps-view").show();
                $(".step-circle-12").addClass("circle-active");
                break;
            case "form-13":
                $("#login-register").show();
                $(".form-view-13").show();

                $(".steps-view").show();
                $(".step-circle-13").addClass("circle-active");
                break;
            case "form-14":
                $("#login-register").show();
                $(".form-view-14").show();

                $(".steps-view").show();
                $(".step-circle-14").addClass("circle-active");
                break;
            case "form-15":
                $("#login-register").show();
                $(".form-view-15").show();

                $(".steps-view").show();
                $(".step-circle-15").addClass("circle-active");
                break;

            case "form-16":
                $("#termosCondicoesBtn").off("click");
                $("#login-register").show();
                $(".termos-condicoes").show();
                $(".login-intro").hide();
                $("#termosCondicoesBtn").one("click", function () {
                    registerShowForm("form-17");
                });

                $(".steps-view").show();
                $(".step-circle-16").addClass("circle-active");
                break;
            case "form-17":
                $("#privacidadeBtn").off("click");
                $("#login-register").show();
                $(".privacidade").show();
                $(".login-intro").hide();
                $("#privacidadeBtn").bind("click", function () {
                    $("#privacidadeBtn").off("click");
                    registerUser();
                });

                $(".steps-view").show();
                $(".step-circle-17").addClass("circle-active");
                break;
            case "form-18":
                $("#login-register").show();
                $(".form-view-18").show();
                $(".login-intro").show();

                $(".steps-view").show();
                $(".step-circle-18").addClass("circle-active");
                break;

            case "loader":
                $("#login-register").show();
                $(".form-view-loader").show();
                $(".login-intro").show();
                break;
            case "error":
                $("#login-register").show();
                $(".form-view-error").show();
                $(".login-intro").show();
                break;
        }
    };

    const forgotShowForm = form => {
        hideAll();
        $("#txtLogin").html("Você está quase lá. Faça parte do programa de relacionamento da Dell que oferece benefícios exclusivos para profissionais de TI!");
        switch (form) {
            case "form-01":
                $("#login-register").show();
                $(".form-view-01").show();

                $(".steps-view").show();
                $(".step-circle-01").addClass("circle-active");
                break;
            case "form-02":
                $("#login-register").show();
                $(".form-view-02").show();
                break;
        }
    };

    $("#login-menu .view-login").click(function () {
        showLogin();
    });

    $("#login-menu .view-access").click(function () {
        hideAll();
        $("#login-first-access").show();
        $("#txtLogin").html("Você está quase lá. Faça parte do programa de relacionamento da Dell que oferece benefícios exclusivos para profissionais de TI!");
    });
    $(".to-first-access").click(function () {
        hideAll();
        $("#login-first-access").show();
        $(".form-first-access-01").show();
        $("#txtLogin").html("Você está quase lá. Faça parte do programa de relacionamento da Dell que oferece benefícios exclusivos para profissionais de TI!");
    });

    $(".to-forgot").click(function () {
        showForgot();
    });

    $(".to-login").click(function () {
        showLogin();
    });

    $(".to-form-01").click(function () {
        registerShowForm("form-01");
    });

    $(".to-form-02").click(function () {
        registerShowForm("form-02");
    });

    $(".to-form-03").click(function () {
        registerShowForm("form-03");
    });

    $(".to-form-04").click(function () {
        registerShowForm("form-04");
    });

    $(".to-form-05").click(function () {
        registerShowForm("form-05");
    });

    $(".to-form-06").click(function () {
        registerShowForm("form-06");
    });

    $(".to-form-07").click(function () {
        registerShowForm("form-07");
    });

    $(".to-form-08").click(function () {
        registerShowForm("form-08");
    });

    $(".to-form-09").click(function () {
        registerShowForm("form-09");
    });

    $(".to-form-10").click(function () {
        $('.txt-error-required-service').html("")
        registerShowForm("form-10");
    });

    $(".to-form-11").click(function () {
        registerShowForm("form-11");
    });

    $(".to-form-12").click(function () {
        registerShowForm("form-12");
    });

    $(".to-form-13").click(function () {
        registerShowForm("form-13");
    });

    $(".to-form-14").click(function () {
        registerShowForm("form-14");
    });

    $(".to-form-15").click(function () {
        registerShowForm("form-15");
    });

    $(".to-form-16").click(function () {
        registerShowForm("form-16");
    });

    $(".to-form-17").click(function () {
        registerShowForm("form-17");
    });

    $(".to-form-18").click(function () {
        registerShowForm("form-18");
    });

    //VALIDATIONS FORM CADASTRO
    $("#form-name").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-02");
    });

    $("#form-last-name").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-03");
    });

    $("#form-cpf").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-04");
    });

    $("#cpf").change(function () {
        let cpf = $(this).val();
        cpf.replace(".", "").replace("-", "");
        if (cpf.length === 11) {
            const res = TestaCPF(cpf);
            if (!res) {
                this.setCustity("CPF inválido!");
            } else {
                this.setCustomValidity(null);
            }
        }
    });

    $("#form-email").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-05");
    });

    $("#form-celular").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-06");
    });

    $("#form-cep").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-07");
    });

    $("#form-endereco").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-08");
    });

    $("#form-bairro").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-09");
    });

    $("#form-cidade").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-10");
    });

    $("#form-estado").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-11");
    });

    $("#form-atividade").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-12");
    });

    $("#form-servico").on("submit", function (e) {
        e.preventDefault();
        if(getServicesChecked()){
            registerShowForm("form-13");
        }
    });
    $("#form-cliente").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-14");
    });
    $("#form-client-profile").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-15");
    });
    $("#form-indicacao").on("submit", function (e) {
        e.preventDefault();
        registerShowForm("form-16");
    });

    $("#termosCondicoesBtn").one("click", function () {
        registerShowForm("form-17");
    });

    $(".show-termo").bind("click", function () {
        hideAll();
        $(".termos-condicoes").show();
        $(".login-intro").hide();
        // $('.show-termo').unbind('click');
        $("#termosCondicoesBtn")
            .unbind("click")
            .one("click", function () {
                $(".termos-condicoes").hide();
                $(".login-intro").show();
                $("#login-first-access").show();
                $(".form-first-access-01").show();
                $(".accepts-terms").prop("checked", true);
                $("#txtLogin").html("Você está quase lá. Faça parte do programa de relacionamento da Dell que oferece benefícios exclusivos para profissionais de TI!");
            });
    });

    $(".privacidade .back-to-menu").click(function () {
        $("#accepts-privacy").prop("checked", false);
    });
    $(".termos-condicoes .back-to-menu").click(function () {
        $("#accepts-terms").prop("checked", false);
    });

    $(".show-privacidade").bind("click", function () {
        hideAll();
        $(".privacidade").show();
        $(".login-intro").hide();
        // $('.show-privacidade').unbind('click');
        $("#privacidadeBtn")
            .unbind("click")
            .one("click", function () {
                $(".privacidade").hide();
                $(".login-intro").show();
                $("#login-first-access").show();
                $(".form-first-access-01").show();
                $(".accepts-privacy").prop("checked", true);
                $("#txtLogin").html("Você está quase lá. Faça parte do programa de relacionamento da Dell que oferece benefícios exclusivos para profissionais de TI!");
            });
    });

    function registerUser() {
        registerShowForm("loader");
        var settings = {
            url: api_url + "auth/signup",
            method: "POST",
            timeout: 0,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: {
                email: $("#email").val(),
                name: $("#name").val(),
                last_name: $("#last_name").val(),
                tel1: $("#celular").val(),
                cpf: $("#cpf").val(),
                address: $("#address").val(),
                neighborhood: $("#neighborhood").val(),
                city: $("#city").val(),
                state: $("#state").val(),
                zipcode: $("#cep").val(),
                aceite: "1",
                privacy: "1",
                question1: $("#activity :selected").val(),
                question4: getValuesInputValues(),
                question5: $("#client :selected").val(),
                question6: $("#qtdFuncionario :selected").val(),
                question7: $("#indication :selected").val()
            }
        };

        $.ajax(settings)
            .done(function (response) {
                localStorage.disableModals = true;
                registerShowForm("form-18");
            })
            .fail(function (error) {
                $(".list-errors").html("");
                let errors = 0;
                if (error.responseJSON) {
                    Object.entries(error.responseJSON).forEach(error => {
                        const elementDiv = document.createElement("div");
                        if (error[1][0] == "The user is active.") {
                            elementDiv.innerHTML =
                                "Foi identificado que já existe um cadastro com este Email e/ou CPF, se você está tentando recuperar a sua senha, <a href='#' class='to-forgot-valid'> clique aqui. </a>";
                            errors++;
                            $(".list-errors").html(elementDiv);
                        }
                        else if (error[1][0] == "The user is inactive.") {
                            elementDiv.innerHTML =
                                "Foi identificado que este Email e/ou CPF já fizeram parte de um cadastro do programa, que hoje encontra-se inativo. Para reativá-lo, entre em contato com <b>0800 701 1293</b>.";
                            errors++;
                            $(".list-errors").html(elementDiv);
                        }
                    });
                    $(".to-forgot-valid").click(function () {
                        showForgot();
                    });
                }
                if (errors == 0) {
                    const elementDiv = document.createElement("div");
                    elementDiv.innerText =
                        "Ops, houve algum erro com suas informações.";
                    $(".list-errors").append(elementDiv);
                }
                registerShowForm("error");
            });
    }

    function getValuesInputValues() {
        var vetor = [];
        if ($("#service-1:checked").val()) {
            vetor.push($("#service-1:checked").val());
        }
        if ($("#service-2:checked").val()) {
            vetor.push($("#service-2:checked").val());
        }
        if ($("#service-3:checked").val()) {
            vetor.push($("#service-3:checked").val());
        }
        if ($("#service-4:checked").val()) {
            vetor.push($("#service-4:checked").val());
        }
        if ($("#service-5:checked").val()) {
            vetor.push($("#service-5:checked").val());
        }
        return vetor.toString();
    }

    function getServicesChecked(){
        if (($("#service-1:checked").val()) || ($("#service-2:checked").val()) || ($("#service-3:checked").val()) || ($("#service-4:checked").val()) || ($("#service-5:checked").val())) {
            return true;
        }
        else{
            const elementDiv = document.createElement("div");
            elementDiv.innerHTML = "<p style='color:#dc3545' >Selecionar uma opção</p>";
            $(".txt-error-required-service").html(elementDiv);
            return false;
        }
    }

    function searchCep(CEP) {
        CEP = CEP.replace('-', '');

        var settings = {
            url: "https://viacep.com.br/ws/" + CEP + "/json/",
            method: "GET",
            timeout: 0,
            headers: {
                "Content-Type": "application/json"
            }
        };

        $.ajax(settings)
            .done(function (response) {
                $("#address").val(response.logradouro),
                    setAbleButton("#address"),
                    $("#neighborhood").val(response.bairro),
                    setAbleButton("#neighborhood"),
                    $("#city").val(response.localidade),
                    setAbleButton("#city"),
                    $("#state").val(response.uf),
                    setAbleButton("#state");
            })
            .fail(function (error) { });
    }

    function setAbleButton(field) {
        if ($(field).val()) {
            let btnStep = $(field).closest(".login-input").closest("form").find(".btn-step");
            btnStep.css({ opacity: 1 });
            btnStep.prop("disabled", false);
        }
    }


})(jQuery, window);
