import moment from "moment";

Vue.component('news-categories', () => import('./news-categories.vue'))
Vue.component('lasted-news', () => import('./lasted-news.vue'))
Vue.component('see-too-news-carousel', () => import('./see-too-news-carousel.vue'))

var key = $('#newsID').data('slug') || $('#newsID').data('id')

new Vue({
    el: '#news-detail',
    data: {
        model: {},
        categories: {},
        currentCategory: {},
    },
    created: function () {
        this.getItem()
        this.getCategories()
    },
    mounted: function () {
        //
    },
    methods: {
        async getItem() {
            var self = this;
            let response = await $.ajax({
                url: `${api_url}news/${key}`,
                headers: $headerAuth
            })

            response.date = moment(response.date).format('DD/MM/YYYY HH:mm')
            self.currentCategory = response.category.slug

            self.model = response

            document.title = `${document.title} | ${self.model.title}`;
        },
        async getCategories() {
            var self = this
            let response = await $.ajax({
                url: `${api_url}news/categories?status=1`,
                headers: $headerAuth
            })
            response.data = response.data.filter(category => category.news_count > 0)
            self.categories = response
        }
    }
});
