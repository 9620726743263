import moment from "moment";
moment.locale('pt-br');

new Vue({
    el: '#histories',
    data: {
        model: {},
    },
    created: function(){
        this.getItems();
    },
    mounted: function(){
    },
    methods:{
        async getItems(page = 1){
            var self = this;
            let response = await axios.get(
                `${api_url}account/history?page=${page}`,
                {headers: $headerAuth}
            );
            let histories = response.data;

            histories.data.forEach(history => {
                history.date = moment(history.date, "YYYY-MM-DD H:mm:ss").calendar();
                history.totalQuantity = history.products.reduce((total, product) => total + product.quantity, 0)
            });

            self.model = histories;
        },
        getItemsByPage(page){
            this.getItems(page);
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#histories").offset().top - 100
            }, 0);
        },
    }
});
