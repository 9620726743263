import moment from "moment";
moment.locale('pt-br');

new Vue({
    el: '.user-account',
    data: {
        user: {},
        account: {},
    },
    created: function(){
        this.getUser()
        this.getAccount()
    },
    mounted: function(){
        //
    },
    methods:{
        async getUser() {
            var self = this
            const { data } = await $getUser
            data.first_name = data.name.split(" ").slice(0, 1).join(" ")
            data.last_name = data.name.split(" ").slice(1).join(" ")
            data.first_name_lowercase = data.first_name.toLowerCase()
            self.user = data
        },
        async getAccount() {
            var self = this
            let account = await $getAccount
            account.points = account.points.addDotE3d()

            account.credits = account.credits > 0 ?
                account.credits.addDotE3d() + " Créditos" : '-'

            account.credits_due_month = account.credits_due_month > 0
                ? account.credits_due_month.addDotE3d() + " Créditos" : '-'

            account.last_purchase_dmy = account.last_purchase != null
                ? moment(account.last_purchase.date).calendar() : '-'

            const situations = [
                'Aguardando',
                'Processado',
                'Entregue',
                'Em Produção'
            ]

            account.last_situation = account.last_purchase != null
                ? situations[account.last_purchase.status-1]
                : 'Nenhum pedido em andamento'

            self.account = account
        }
    }
});
