const resetValidation = () => {
    $(".login-error").removeClass("show");
    $(".login-success").removeClass("show");
    $("#passwordInput").removeClass("error");
};

$("#passwordInput").click(resetValidation);
$("#emailInput").click(resetValidation);
$("#passwordInput").change(resetValidation);
$("#emailInput").change(resetValidation);

$("#form-login").on("submit", function(e) {
    e.preventDefault();
    resetValidation();
    $.ajax({
        crossDomain: true,
        type: "POST",
        url: api_url + "auth/login",
        contentType: "application/x-www-form-urlencoded",
        dataType: "json",
        data: $(this).serializeArray(),
        success: function(data) {
            $(".login-success").addClass("show");
            localStorage.token = data.token;
            localStorage.user_id = data.id;
            localStorage.show_message = true;
            setTimeout(() => {
                window.location.href = (localStorage.path) ? localStorage.path : "/";
            }, 2 * 1000);
        },
        error: function() {
            $(".login-error").addClass("show");
            $("#passwordInput").addClass("error");
        }
    });
});

let validEmail = false;
let hasValidatedEmail = false;
$("#form-first-access").on("submit", function(e) {
    e.preventDefault();
    $(".first-access-error").hide();

    var settings = {
        url: api_url + "user/first-access",
        method: "POST",
        timeout: 0,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {
            email: $("#first-access-email").val()
        }
    };

    $.ajax(settings)
        .done(function(response) {
            $(".first-access-show-on-success").show();
            $(".first-access-hide-on-success").hide();
        })
        .fail(function(error) {
            $(".first-access-error").show();
            var message = 'E-mail inválido ou não encontrado';
            if((error) && (error.responseJSON)){
                if(error.status == 422){
                    if(error.responseJSON.data == "E-mail já possui senha."){
                        message = "Foi identificado que já existe um cadastro com este E-mail, se você está tentando recuperar a sua senha, <a href='#' class='to-forgot-valid'> clique aqui. </a>";
                    }
                    else if(error.responseJSON.data == "E-mail deste usuário ainda não está ativo."){
                        message = "Foi identificado que este E-mail encontra-se inativo. Para ativá-lo, entre em contato com <b>0800 701 1293</b>."
                    }
                }
            }

            $(".first-access-error").html('<span>'+message+'</span><br><img src="img/warning.png">');

            $(".to-forgot-valid").click(function () {
                showForgot();
            });

        });
});

const countdown = $("#countdown").countdown360({
    radius: 50,
    strokeWidth: 8,
    seconds: 300,
    strokeStyle: "#00447C",
    fontColor: "#808080",
    fontSize: 35,
    label: false,
    fillStyle: "#fff",
    autostart: false,
    onComplete: function() {
        $(".btn-disabled").prop("disabled", false);
        $(".btn-step").removeClass("btn-disabled");
        $("#countdown").hide();
        $("#countdownEnded").show();
    }
});

$("#btn-code").click(function() {
    countdown.start();
    $("#btn-form-forgot").addClass("btn-disabled");
    $("#btn-form-forgot").prop("disabled", true);
    $("#countdown").show();
    $("#countdownEnded").hide();
});

$("#btn-form-forgot").click(function() {
    $("#form-forgot").submit();
    countdown.start();
    $("#btn-form-forgot").addClass("btn-disabled");
    $("#btn-form-forgot").prop("disabled", true);
    $("#countdown").show();
    $("#countdownEnded").hide();
});

$("#form-forgot").on("submit", function(e) {
    e.preventDefault();
    resetValidation();
    $(".submit-code .spinner-border").show();
    $(".recovery-error").hide();
    $(".submit-code input").addClass("btn-disabled");
    $(".submit-code input").prop("disabled", true);

    $.ajax({
        crossDomain: true,
        type: "POST",
        url: api_url + "user/password-recovery",
        contentType: "application/x-www-form-urlencoded",
        dataType: "json",
        data: $(this).serializeArray(),
        success: function(data) {
            $(".hide-form-code").show();
            $(".hide-form-email").hide();
            $("#btn-form-forgot").show();
            $("#btn-form-forgot-hide").hide();
            $("#btn-form-forgot").addClass("btn-disabled");
            $("#btn-form-forgot").prop("disabled", true);

            countdown.start();
            $(".code-id").val(data.id);
            $("#insertCode").keyup(function() {
                $("#insertCodeReady").val($(this).val());
            });
        },
        error: function() {
            $(".recovery-error").show();
            $(".submit-code input").removeClass("btn-disabled");
            $(".submit-code input").prop("disabled", false);
            $(".submit-code .spinner-border").hide();
        }
    });
});

$("#form-code").on("change", function(e) {
    e.preventDefault();
    resetValidation();
    $.ajax({
        crossDomain: true,
        type: "POST",
        url: api_url + "user/check-password-recovery-code",
        contentType: "application/x-www-form-urlencoded",
        dataType: "json",
        data: $(this).serializeArray(),
        success: function(data) {
            if (data.success == true) {
                $(".code-success").show();
                $(".btn-reset-password").show();
                $(".submit-code").hide();
                $(".code-insert.login-error").hide();
            } else {
                $(".code-success").hide();
                $(".code-insert.login-error").show();
                $(".submit-code").show();
                $(".btn-reset-password").hide();
            }
        },
        error: function() {
            $(".code-success").hide();
            $(".code-insert.login-error").show();
            $(".submit-code").show();
            $(".btn-reset-password").hide();
        }
    });
});

$(".btn-reset-password").click(function() {
    $("#login-forgot").hide();
    $(".change-password").show();
    $("#txtLogin").html("Você está quase lá. Faça parte do programa de relacionamento da Dell que oferece benefícios exclusivos para profissionais de TI!");
});

$("#form-password").on("submit", function(e) {
    e.preventDefault();
    resetValidation();
    $.ajax({
        crossDomain: true,
        type: "POST",
        url: api_url + "user/change-password",
        contentType: "application/x-www-form-urlencoded",
        dataType: "json",
        data: $(this).serializeArray(),
        success: function(data) {
            $(".password-error-length").hide();
            $("#form-password").hide();
            $("#password-check").show();
            setTimeout(function() {
                $(".change-password").hide();
                $("#login-access").show();
                $("#txtLogin").html("Faça seu login para visualizar o seu perfil do programa, informações de conta, extrato de créditos, resgate de prêmios, e mais!");
            }, 30000);
            document.getElementById("emailInput").value = "";
            document.getElementById("passwordInput").value = "";
            document.getElementById("insertCode").value = "";
            document.getElementById("forgotCpf").value = "";
            document.getElementById("forgotEmail").value = "";
            document.getElementById("password").value = "";
            document.getElementById("password_confirmation").value = "";
            document.getElementById("insertCode").value = "";
        },
        error: function(xhr, textStatus, errorThrown) {
            $(".password-error-length").text(xhr.responseText).show();
        }
    });
});

var hideAll = function(){
    $("#login-access").hide();
    $("#login-first-access").hide();
    $("#login-register").hide();
    $("#login-menu").hide();
    $(".form-view-loader").hide();
    $(".form-view-error").hide();
    //Cadastro
    $(".form-view-01").hide();
    $(".form-view-02").hide();
    $(".form-view-03").hide();
    $(".form-view-04").hide();
    $(".form-view-05").hide();
    $(".form-view-06").hide();
    $(".form-view-07").hide();
    $(".form-view-08").hide();
    $(".form-view-09").hide();
    $(".form-view-10").hide();
    $(".form-view-11").hide();
    $(".form-view-12").hide();
    $(".form-view-13").hide();
    $(".form-view-14").hide();
    $(".termos-condicoes").hide();
    $(".privacidade").hide();
    $(".form-view-17").hide();

    $(".steps-view").hide();
    $(".steps-circle").removeClass("circle-active");
    //Esqueci minha senha
    $("#login-forgot").hide();
    $(".change-password").hide();
};

var showForgot = function(){
    hideAll();
    $("#login-forgot").show();
    $("#txtLogin").html("Adicione nos campos ao lado o seu e-mail e CPF cadastrados no programa e receba por e-mail o código para recuperar a sua senha.");
};
