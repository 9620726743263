const rescueProductsFiltered = $RESCUE_PRODUCTS.filter(el => el != null);

if ($('#request-confirmation').length) {
    new Vue({
        el: '#request-confirmation',
        data: {
            credits: 0,
            user: {},
            rescueProducts: {},
            rescueProductsPoints: rescueProductsFiltered.reduce((total, purchase) => total + (purchase.points * purchase.qty), 0),
            rescueProductsQty: rescueProductsFiltered.reduce((total, purchase) => total + purchase.qty, 0),
            message: '',
            validProduct: false
        },
        created: function () {
            this.getRescueProducts();
            this.getAccount();
            this.getUser();
            this.get();
        },
        mounted: function () {
            //
        },

        methods: {
            getRescueProducts() {
                var self = this;
                self.rescueProducts = rescueProductsFiltered;
            },
            async get() {
                var self = this;
                var name_product = false;
                if (self.rescueProducts) {
                    let products = self.rescueProducts;
                    for (const key in products) {
                        const element = products[key];
                        let slug = element['id'];
                        const response = await axios.get(`${api_url}product/${slug}`, { headers: $headerAuth });
                        name_product = (!response.data.active) ? response.data.name : false;
                    }
                }
                self.validProduct = name_product;
            },
            async getAccount() {
                var self = this;
                let response = await $getAccount;
                self.credits = response.credits;
            },
            async getUser() {
                var self = this;
                let response = await $getUser;
                let info_data = response.data;
                // self.user = response.data;

                //format cpf
                let cpf = info_data.cpf.replace(/[^\d]/g, "");
                self.user.cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            },
            post() {
                var self = this;
                $('.request-buttons button[type=submit]').prop('disabled', true);
                let form = getFormData(self.$refs.form);
                form.cpf = self.user.cpf;

                //get purchase and send products
                const temp_products = self.rescueProducts.map(purchase => purchase = { id: purchase.id, qtd: purchase.qty });
                form.products = JSON.stringify(temp_products);

                //validade products of list 
                let checkProduct = self.validProduct;
                if (checkProduct) {
                    $('#danger-message').show();
                    $('#danger-message').html(checkProduct + ' não pode ser mais resgatado, favor remover na lista.');
                    $('body').css({ 'opacity': '1' });

                    setTimeout(() => {
                        $('.m-alert').fadeOut('slow');
                        $('.request-buttons button[type=submit]').prop('disabled', false);
                    }, 3000);
                
                }
                else {
                    //validate field before send data
                    let checkField = self.validateInputs();

                    if (checkField) {
                        axios.post(
                            `${api_url}transaction/purchase`,
                            form,
                            {
                                headers: {
                                    Authorization: "Bearer " + localStorage.token,
                                    "Content-Type": "application/json"
                                }
                            }
                        ).then(function (data) {
                            $('#alert-message').show();
                            $('#alert-message').text('Seu pedido foi criado com sucesso.');
                            localStorage.removeItem('rescueProducts');

                            setTimeout(() => {
                                window.location.href = $('#simulateHistory').data('href');
                            }, 3000);
                        })
                        .catch(error => {
                            $('#danger-message').show();
                            if ((error) && (error.response)) {
                                if (error.response.status == 422) {
                                    if (error.response.data.message) {
                                        $('#danger-message').html(error.response.data.message);
                                    }
                                    else if (error.response.data.zipcode) {
                                        $('#danger-message').html('CEP inválido');
                                    }
                                    else if (error.response.data.products) {
                                        $('#danger-message').html('Produto inválido');
                                    }
                                    else {
                                        $('#danger-message').html('*Você não tem créditos suficientes.');
                                    }
                                }
                                else if (error.response.statusText) {
                                    $('#danger-message').html(error.response.statusText);
                                }
                                else {
                                    $('#danger-message').html('*Você não tem créditos suficientes.');
                                }
                            }
                            else {
                                $('#danger-message').html(error.message);
                            }

                            $('body').css({ 'opacity': '1' });
                            setTimeout(() => {
                                $('.m-alert').fadeOut('slow');
                                $('.request-buttons button[type=submit]').prop('disabled', false);
                            }, 3000);
                        });
                    }
                }


            },
            findPlaceByCEP() {
                var self = this;
                let cep = self.user.zipcode;
                if (cep) {
                    cep = cep.replace('-', '');
                    axios.get(
                        "https://viacep.com.br/ws/" + cep + "/json/",
                        {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        }
                    ).then(function (data) {
                        self.user.address = data.data.logradouro,
                            self.user.neighborhood = data.data.bairro,
                            self.user.city = data.data.localidade,
                            self.user.state = data.data.uf
                    });
                }

            },

            validateInputs() {
                let fields = ['zipcode', 'address', 'neighborhood', 'city', 'state'];
                let errors = 0;

                for (let i = 0; i < fields.length; i++) {
                    let field = document.getElementsByName(fields[i]);
                    if (!field[0].value) {
                        field[0].style = 'border-color: #ce1126;';
                        errors++;
                    }
                    else {
                        field[0].style = 'border-color: #c4c4c4;';
                    }
                }

                if (errors > 0) {
                    $('#danger-message').show();
                    $('#danger-message').html('*Existem campos a serem preenchidos.');
                    return false;
                }

                return true;
            }

        }
    });
}

