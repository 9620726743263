(function($, w) {
    //get rescueProducts (localStorage)
    w.$RESCUE_PRODUCTS = [];
    w.updateKeysRescueProducts = function(array) {
        let temp_products = [];
        array.forEach(rescueProduct => {
            temp_products[rescueProduct.id] = rescueProduct;
        });
        w.$RESCUE_PRODUCTS = temp_products;

        if ((temp_products.length) && (localStorage.getItem("user_id"))) {
            $('.ifRescueProducts').css({'display' : 'block'});
            $('.product-add-message').css({'display' : 'block'});
            $('.close-add').css({'display' : 'none'});
            $(".rescue-menu .ball").css("background", "#41b6e6");
        } else {
            $('.ifRescueProducts').hide({'display' : 'none'});
            $(".number-notifications").css("display", "none");
        }
    }

    /**
     * Add dots Every 3 Digits
     */
    if (!Number.prototype.addDotE3d) {
        Number.prototype.addDotE3d = function() {
            return this.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
        }
    }

    w.updateRescueProduct = function(data) {
        //check exists rescueProduct and add qty
        if (($RESCUE_PRODUCTS[data.id] || false)) {
            $RESCUE_PRODUCTS[data.id].qty = $RESCUE_PRODUCTS[data.id].qty + data.qty;
        } else {
            $RESCUE_PRODUCTS[data.id] = data;
        }

        var filtered = $RESCUE_PRODUCTS.filter(el => el != null);
        localStorage.rescueProducts = JSON.stringify(filtered);
        setTimeout(function () {
            $(".close-add").fadeOut( "slow" );
        }, 5000);

        $('.product-add-message').css({'display':'block'});
        $('.product-add-message .close-add').fadeIn( "slow" );
        $(".number-notifications").css("display", "flex");
        $('.ifRescueProducts').css({'display' : 'block'});
        $(".rescue-menu .ball").css("background", "#41b6e6");
        return $RESCUE_PRODUCTS;
    }
    w.updateKeysRescueProducts(localStorage.rescueProducts ? JSON.parse(localStorage.rescueProducts) : []);

    //get form data
    w.getFormData = function(form) {
        var newForm = {};
        for (const field of form) {
            if (field.name) {
                newForm[field.name] = field.value;
            }
        }
        return newForm;
    }

})(jQuery, window);
