
new Vue({
    el: '#points',
    data: {
        model: {},
    },
    created: function(){
        this.getItems()
    },
    mounted: function(){

    },
    methods:{
        async getItems(){
            var self = this
            const account = await $getAccount
            const category = account.category
            const categories = {
                1 : { pointsGoal: 20001, indicationsGoal: 3},
                2 : { pointsGoal: 80001, indicationsGoal: 10},
                3 : { pointsGoal: 80001, indicationsGoal: 10,}
            }

            category.points = account.points
            category.pointsGoal = categories[category.id].pointsGoal
            category.pointsPercentage = account.points >= category.pointsGoal ? '100%' : 100*account.points/category.pointsGoal+'%'
            category.pointsDiff = category.pointsGoal - account.points

            category.indications = account.indications
            category.indicationsGoal = categories[category.id].indicationsGoal
            category.indicationsPercentage = account.indications >= category.indicationsGoal ? '100%' : 100*account.indications/category.indicationsGoal+'%'
            category.indicationsDiff = category.indicationsGoal - account.indications

            self.model = category
        },
        addDotsEvery3Digits (value) {
            if(value) return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
            return value
        }
    }
})
