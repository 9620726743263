const form = document.getElementById("contact-us");

const submitSuccess = document.querySelector("#contact-us .submit-success");
const submitError = document.querySelector("#contact-us .submit-error");
const submitting = document.querySelector("#contact-us .submitting");
const formBtn = document.querySelector("#contact-us .btn-form");
const formItems = document.querySelectorAll("#contact-us .form-item");

function rmBorderFormItems() {
    formItems.forEach(element => {
        element.style.border = '';
    });
}

function fnSubmitting(is_success, message, elementMessage){
    elementMessage.innerHTML = '';
    submitting.style.display = 'none';

    rmBorderFormItems();

    if(is_success){
        elementMessage.innerText = message;
        form.reset();
    } else if (message.errors) {
        Object.entries(message.errors).forEach(error => {
            const elementDiv = document.createElement('div');
            const elementInput = document.querySelector('#contact-us [name='+error[0]+']');
            elementInput.style.border = '2px solid #fb9595';
            elementDiv.innerText = error[1][0];
            elementMessage.append(elementDiv);
        });
    } else {
        elementMessage.innerText = 'Ops, houve algum erro ao enviar o formulário.';
    }

    elementMessage.style.display = 'block';

    setTimeout(function(){
        elementMessage.style.display = 'none';
        formBtn.style.opacity = '1';
        formBtn.removeAttribute("disabled");
    }, 3000);
}

if(form){
    form.onsubmit = function (e){
        e.preventDefault();

        formBtn.disabled = true;
        formBtn.style.opacity = '0.5';
        const data = $(form).serialize();
        submitting.style.display = 'block';

        $.ajax({
            method: 'POST',
            crossDomain: true,
            url: form.getAttribute('action'),
            contentType: "application/x-www-form-urlencoded",
            dataType: 'json',
            data: data,
            success: function(result){
                fnSubmitting(true, result.message, submitSuccess);
            },
            error: function(error){
                fnSubmitting(false, error.responseJSON, submitError);
            }
        });
    };
}
