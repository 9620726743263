function redirectToHome(array) {
    array.forEach(page => {
        if(window.location.pathname == `/${page}`)
            window.location.href= "/";
    });
}

if (!localStorage.getItem("user_id")) {
    redirectToHome([
        'pontuacao',
        'notificacoes',
        'perfil',
        'pedido',
        'pedido-confirmacao',
        'movimentacoes/extrato',
        'movimentacoes/historico',
        'treinamentos',
        'meu-aprendizado',
    ]);
} else {
    redirectToHome([
        'acesso'
    ]);
}

