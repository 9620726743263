require("./auth/redirects.js");
require("./plugins/bootstrap");
require("./plugins/slick.min.js");
require("./plugins/jquery.e-calendar");
require("./plugins/blockadblock.js");
require("./plugins/jquery.countdown360.min");
require("./plugins/jquery.toast.min.js");
require("./plugins/css/jquery.toast.min.css");
const simpleParallax = require("./plugins/simpleParallax.min.js");

(function($, w) {
    w.api_url = process.env.MIX_API_URL;

    $(window).on("load", function() {
        $("#preloader .inner").fadeOut();
        $("#preloader")
            .delay(350)
            .fadeOut("slow");
        $("body")
            .delay(350)
            .css({ overflow: "visible" });
    });

    w.$headerAuth = localStorage.token
        ? { Authorization: `Bearer ${localStorage.token}` }
        : {};
    w.$getUser = null;
    w.$getUserNotifications = null;
    w.$getAccount = null;

    if(window.location.pathname != '/acesso') localStorage.path = window.location.href;

    if (localStorage.getItem("token") === null) {
        clearLocalStorage();
    }

    w.showRequestLoader = () => {
        $("#request-loader").show();
        setTimeout(function() {
            $("#request-loader").hide();
        }, 30000);
    };
    w.hideRequestLoader = () => $("#request-loader").hide();
    
    let requestProccess = 0;
    $(document).ajaxSend(function () {
        ++requestProccess;
        w.showRequestLoader();
    });
    $(document).ajaxComplete(function () {
        --requestProccess;
        if (requestProccess <= 0) w.hideRequestLoader();
    });

    axios.interceptors.request.use(
        function (config) {
            ++requestProccess;
            w.showRequestLoader();
            return config;
        },
        function (error) {
            --requestProccess;
        if (requestProccess <= 0) w.hideRequestLoader();
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            --requestProccess;
        if (requestProccess <= 0) w.hideRequestLoader();
          return response;
        },
        function (error) {
            --requestProccess;
        if (requestProccess <= 0) w.hideRequestLoader();
          return Promise.reject(error);
        }
    );

    if (localStorage.getItem("user_id")) {

        const successFunction = function(data) {
            if (data.count_notifications_unread > 0) {
                $(".number-notifications").css("display", "block");
            } else {
                $(".number-notifications").css("display", "none");
            }
        };

        const errorFunction = function(request, status, error) {
            if (request.status === 401) {
                clearLocalStorage();
            }
        };
        w.$getUser = axios.get(
            `${api_url}user/${localStorage.getItem("user_id")}`,
            { headers: $headerAuth }
        );
        w.$getUserNotifications = $.ajax({
            url: `${api_url}notification/user`,
            headers: $headerAuth,
            crossDomain: true
        });

        w.$getAccount = $.ajax({
            url: `${api_url}account`,
            headers: $headerAuth,
            crossDomain: true,
            success: successFunction,
            error: errorFunction
        });
    }

    require("./auth/authFlow");
    require("./orders/rescueFlow");
    require("./validElementsToRequire.js");
    require("./cpfValidation");
    require("./custom");
    require("./functions");
    var image = document.getElementsByClassName("thumbnail");
    new simpleParallax(image, {
        scale: 1.2,
        delay: 1,

        transition: "cubic-bezier(0,0,0,1)"
    });

    if (localStorage.show_message === "true") {
        localStorage.show_message = false;
        $.toast({
            text: "Login efetuado com sucesso!",
            position: "bottom-right",
            textColor: "white",
            bgColor: "#6EA204"
        });
    }

    function clearLocalStorage() {
        localStorage.removeItem("user_id");
        localStorage.removeItem("token");
        localStorage.removeItem("show_message");
    }

    $("#form-password input").blur(function() {
        var pass = $("#password").val();
        var repass = $("#password_confirmation").val();

        if (pass != repass) {
            $(".password-error").show();
            $("#btn-form-password").addClass("btn-disabled");
            $("#btn-form-password").prop("disabled", true);
        } else {
            $(".password-error").hide();
            $(".password-error-length").hide();
            $("#btn-form-password").removeClass("btn-disabled");
            $("#btn-form-password").prop("disabled", false);
        }
        if (pass.length <= 0) {
            $("#btn-form-password").addClass("btn-disabled");
            $("#btn-form-password").prop("disabled", true);
        }
    });
})(jQuery, window);

(function() {
    //Se não detectar o adblock
    function adBlockNotDetected() {
        $('#banner-adblock').hide();
    }

    //Se detectar o adblock
    function adBlockDetected() {
        $('#banner-adblock').show();
    }

    let expire = true
    let dateStorage = window.localStorage.getItem('dateHideAdblockbanner')
    if( dateStorage && dateStorage > Date.now()){
        expire = false
    }

    if(typeof blockAdBlock=== 'undefined' || (window.localStorage.getItem('hideAdblockbanner') && !expire))  {
        $('#banner-adblock').hide();
        let date = new Date()
        window.localStorage.setItem('dateHideAdblockbanner', date.setDate(date.getDate() + 30));
    } else {
        blockAdBlock.onDetected(adBlockDetected);
        blockAdBlock.onNotDetected(adBlockNotDetected);
        blockAdBlock.on(true, adBlockDetected);
        blockAdBlock.on(false, adBlockNotDetected);
        blockAdBlock.on(true, adBlockDetected).onNotDetected(adBlockNotDetected);
    }

    blockAdBlock.setOption('checkOnLoad', false);

    blockAdBlock.setOption({
        debug: false,
        checkOnLoad: true,
        resetOnEnd: true
    });
})();
