import moment from "moment";

if(!localStorage.getItem("user_id")) {
    //window.location.href = "/";
}

new Vue({
    el: '#notifications',
    data: {
        model: {},
        countNotifications: 0,
    },
    created: function(){
        this.getItems();
        this.getAccount();

    },
    mounted: function(){
        setTimeout(function(){
            if($("#notifications .notification").hasClass("unread")){
                $(".notifications-menu .ball").css("background", "#41b6e6");
                $(".number-notifications").css("display", "flex");
            }

        }, 1500);

    },
    methods:{
        async getItems(page = 1) {
            var self = this;
            let result = await $.ajax({url: `${api_url}notification/user?page=${page}`, headers: $headerAuth, crossDomain: true});
            result.data.map(notification => {
                const date = new moment(notification.last_history.last_send);

                if (date.isSame(new moment(), "day"))
                    notification.dateString = date.format("HH:mm");

                else if (date.diff(new moment(), "days") === -1)
                    notification.dateString = "Ontem";

                else notification.dateString = date.format("DD/MM/YYYY");

                let cutMessage = notification.message.length >= 95
                    ? notification.message.cutWord(95)
                    : notification.message
                ;

                notification.subtitle = cutMessage !== notification.message
                    ? cutMessage : notification.message;

                notification.ableArrow = cutMessage !== notification.message
                    ? 1 : 0;

            });

            self.model = result;
        },
        eventDeleteById(element, id){
            $(element).parent().parent().parent().remove();
            if(id){
                this.deleteNotifyById([id]);
            }
        },
        async getAccount(){
            var self = this;
            let response = await $getAccount;
            self.countNotifications = response.count_notifications_unread;
            },
        setRead(id) {
            let data = { is_read: "1" };
            id.map((id, index) => {
                data[`notification_id[${index}]`] = id;
            });
            var settings = {
                url: api_url + "notification/user/read?=",
                method: "POST",
                timeout: 0,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + localStorage.token
                },
                data
            };

            $.ajax(settings);
        },
        checkCountNotifications(){
            this.countNotifications -= 1;
            if(this.countNotifications === 0){
                $(".number-notifications").css("display", "none");
            }
        },
        collapse(element, id){
            const collapse = $(element).find(".collapseable");
            collapse.toggleClass("collapsed");
            $(element).find('.subtitle').toggleClass('d-n');
            $(element).find("img").toggleClass("invert");

            $(element).hasClass("read");
            if(id){
                $(element).addClass("read");
                $(element).removeClass("unread");
                this.setRead([id]);
                this.checkCountNotifications();
            }
        },
        getItemsByPage(page){
            this.getItems(page);
            $([document.documentElement, document.body]).animate({
                scrollTop: $('body').offset().top
            }, 0);
        },
        deleteNotifyById(id) {
            let data = { required_id: localStorage.getItem("user_id") };
            id.map((id, index) => {
                data[`notification_id[${index}]`] = id;
            });
            var settings = {
                url: api_url + "user/notification/history",
                method: "DELETE",
                timeout: 0,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + localStorage.token
                },
                data
            };
            $.ajax(settings);
        },
        async getAllNotifyUnread() {
            let result = await $.ajax({ url: `${api_url}notification/user?is_read=0`, headers: $headerAuth, crossDomain: true });
            let vids = [];
            result.data.map(notification => {
                vids.push(notification.id);
            });
            return vids;
        },
        async deleteAllNotifyRead() {
            var self = this;
            let vids = await self.getAllNotifyUnread();
            await self.deleteNotifyById(vids);
            $.toast({
                text: "Notificações deletadas!",
                position: "bottom-right",
                textColor: "white",
                bgColor: "#6EA204"
            });
            await self.checkCountNotifications();
            window.location.reload();
        },
        async setAllRead() {
            var self = this;
            let vids = await self.getAllNotifyUnread();
            await self.setRead(vids);
            $.toast({
                text: "Marcado notificações como lidas!",
                position: "bottom-right",
                textColor: "white",
                bgColor: "#6EA204"
            });
            await self.checkCountNotifications();
            window.location.reload();
        }
    }
});
