import moment from "moment";

new Vue({
    el: '#notifications-header',
    data: {
        model: {}
    },
    created: function(){
        this.get();

    },
    mounted: function(){
        setTimeout(function(){
            if($("#notifications-header .notification").hasClass("unread")){
                $(".notifications-menu .ball").css("background", "#41b6e6");
                $(".number-notifications").css("display", "flex");
            }
            if($('.ifRescueProducts').css('display') == 'block'){
                $(".number-notifications").css("display", "flex");
            }

        }, 1500);

    },
    methods:{
        async get() {
            var self = this;
            let result = await $getUserNotifications;
            result.data.map(notification => {
                const date = new moment(notification.last_history.last_send);

                if (date.isSame(new moment(), "day"))
                    notification.dateString = date.format("HH:mm");

                else if (date.diff(new moment(), "days") === -1)
                    notification.dateString = "Ontem";

                else notification.dateString = date.format("DD/MM/YYYY");

                let cutMessage = notification.message.length >= 95
                    ? notification.message.cutWord(95)
                    : notification.message
                ;

                notification.subtitle = cutMessage !== notification.message
                    ? cutMessage : notification.message;

                notification.ableArrow = cutMessage !== notification.message
                    ? 1 : 0;
            });

            self.model = result;
        },
        setRead(id) {
            let data = { is_read: "1" };
            id.map((id, index) => {
                data = {
                    ...data,
                    [`notification_id[${index}]`]: id
                };
            });
            var settings = {
                url: api_url + "notification/user/read?=",
                method: "POST",
                timeout: 0,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + localStorage.token
                },
                data
            };

            $.ajax(settings);
        },
        collapse(element, id){
            const collapse = $(element).find(".collapseable");
            collapse.toggleClass("collapsed");
            $(element).find('.subtitle').toggleClass('d-n');
            $(element).find("img").toggleClass("invert");

            $(element).hasClass("read");
            if(id){
                $(element).addClass("read");
                $(element).removeClass("unread");
                this.setRead([id]);
            }
        },
    }
});
