(function($, w) {
    if (!String.prototype.cutWord) {
        String.prototype.cutWord = function(maxLength = 50) {
            var trimmedString = this.substr(0, maxLength);
            trimmedString = trimmedString.substr(
                0,
                Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
            );
            return trimmedString !== "" ? trimmedString + "..." : this;
        };
    }

    if (!String.prototype.cutString) {
        String.prototype.cutString = function(maxLength = 50) {
            let html = this;
            let div = document.createElement("div");
            div.innerHTML = html;
            let onlyText = div.textContent || div.innerText || "";
            return (
                onlyText.substring(0, maxLength) +
                (onlyText.length > maxLength ? "..." : "")
            );
        };
    }

    $("#logout").click(function() {
        localStorage.removeItem("user_id");
        localStorage.removeItem("token");
        localStorage.removeItem("show_message");
    });
    $("#meuModal").on("shown.bs.modal", function() {
        $("#meuInput").trigger("focus");
    });
    $(".banner-ancor").click(function(e) {
        e.preventDefault();
        var target = $($(this).attr("href"));
        if (target.length) {
            var scrollTo = target.offset().top;
            $("body, html").animate({ scrollTop: "700px" }, 800);
        }
    });
    $(".steps").slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
    $(".team").slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
    $("#aContact").click(function(){
        $('.alterna-foco').each(function() {
            $(this).attr('tabindex', '0');
        });

        $('.menu').focus();
    });

    $('#aContact').keypress(function(event) {
        if (event.key === 'Enter') {
            $('.alterna-foco').each(function() {
                $(this).attr('tabindex', '0');
            });

            $('.menu').focus();
        }
    });

    $('.box-header-close').on('click', function() {
        $('.alterna-foco').each(function() {
            $(this).attr('tabindex', '-1');
        });
    });

    $('.box-header-close').keypress(function() {
        if (event.key === 'Enter') {
            $('.alterna-foco').each(function() {
                $(this).attr('tabindex', '-1');
            });
        }
    });

    $('#menu-contact').on('focusout', function() {
        if (event.key === 'Enter') {
            $('.alterna-foco').each(function() {
                $(this).attr('tabindex', '-1');
            });
        }
    });

    $(window).ready(function() {
        if ($(window).width() <= 991) {
            $(".tab-list .nav").addClass("category-product");
            $(".category-product").slick({
                slidesToShow: 5,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            arrows: false,
                            centerPadding: "40px",
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            arrows: false,
                            slidesToShow: 3,
                            infinite: false,
                            centerPadding: "10px"
                        }
                    }
                ]
            });
            $("#news-container").addClass("news-home");
        } else {
            $(".category-list").removeClass("category-product");
        }

        //$('.nav-tabs').scrollingTabs();
    });

    $(window).ready(function() {
        if ($(window).width() <= 767) {
            $(".categories .categories-list").addClass("category-carousel");
            $(".category-carousel").slick({
                slidesToShow: 3,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            infinite: false,
                            arrows: false,
                            centerPadding: "40px",
                            slidesToShow: 1,
                            centerMode: true,
                            initialSlide: 1
                        }
                    }
                ]
            });
        } else {
            $(".categories .categories-list").removeClass("category-carousel");
        }

        //$('.nav-tabs').scrollingTabs();
    });

    $("#name").keyup(function() {
        $("#nameReady").text($(this).val());
    });

    w.strip_html_tags = str => {
        if (str === null || str === "") {
            return false;
        } else {
            str = str.toString();
        }

        return str.replace(/<[^>]*>/g, "");
    };

    if (!String.prototype.slugify) {
        String.prototype.slugify = function() {
            return this.toString()
                .toLowerCase()
                .replace(/[àÀáÁâÂãäÄÅåª]+/g, "a") // Special Characters #1
                .replace(/[èÈéÉêÊëË]+/g, "e") // Special Characters #2
                .replace(/[ìÌíÍîÎïÏ]+/g, "i") // Special Characters #3
                .replace(/[òÒóÓôÔõÕöÖº]+/g, "o") // Special Characters #4
                .replace(/[ùÙúÚûÛüÜ]+/g, "u") // Special Characters #5
                .replace(/[ýÝÿŸ]+/g, "y") // Special Characters #6
                .replace(/[ñÑ]+/g, "n") // Special Characters #7
                .replace(/[çÇ]+/g, "c") // Special Characters #8
                .replace(/[ß]+/g, "ss") // Special Characters #9
                .replace(/[Ææ]+/g, "ae") // Special Characters #10
                .replace(/[Øøœ]+/g, "oe") // Special Characters #11
                .replace(/[%]+/g, "pct") // Special Characters #12
                .replace(/\s+/g, "-") // Replace spaces with -
                .replace(/[^\w\-]+/g, "") // Remove all non-word chars
                .replace(/\-\-+/g, "-") // Replace multiple - with single -
                .replace(/^-+/, "") // Trim - from start of text
                .replace(/-+$/, ""); // Trim - from end of text
        };
    }

    $(".copyurlprogram").click(function() {
        const isIOSDevice = navigator.userAgent.match(/ipad|ipod|iphone/i);

        try {
            if (isIOSDevice) {
                var textarea = document.createElement("textarea");
                textarea.contentEditable = true;
                const range = document.createRange();
                range.selectNodeContents(textarea);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
                textarea.setSelectionRange(0, 999999);
            } else {
                var aux = document.createElement("input");
                aux.setAttribute("value", window.location.href);
                document.body.appendChild(aux);
                aux.select();
            }

            var comandCopy = document.execCommand("copy");
            document.body.removeChild(aux);
            if (comandCopy) {
                $(".tooltiptext").addClass("show");
                setTimeout(function() {
                    $(".tooltiptext").removeClass("show");
                }, 5000);
            } else {
                window.prompt(
                    "Copie o endereço para compartilhamento",
                    window.location.href
                );
            }
        } catch (e) {
            window.prompt(
                "Copie o endereço para compartilhamento",
                window.location.href
            );
        }
    });

    var previousActiveTabIndex = 0;
    $(".tab-switcher").on("click keypress", function(event) {
        // event.which === 13 means the "Enter" key is pressed
        if (
            (event.type === "keypress" && event.which === 13) ||
            event.type === "click"
        ) {
            var tabClicked = $(this).data("tab-index");
            if (tabClicked != previousActiveTabIndex) {
                $(".allTabsContainer .tab-container").each(function() {
                    if ($(this).data("tab-index") == tabClicked) {
                        $(".tab-container").hide();
                        $(this).show();
                        previousActiveTabIndex = $(this).data("tab-index");
                        return;
                    }
                });
            }
        }
    });

    var hash = window.location.hash;

    if (hash == "#register") {
        $("#login-register").show();
        $(".form-view-01").show();

        $(".steps-view").show();
        $(".step-circle-01").addClass("circle-active");

        $("#login-access").hide();
        $("#login-first-access").hide();
        $("#login-menu").hide();

        $("#txtLogin").html("Você está quase lá. Faça parte do programa de relacionamento da Dell que oferece benefícios exclusivos para profissionais de TI!");
    }

    // faz com que o a navegação pela tecla tab fique restringida ao menu lateral de fale conosco
    // retornado o foco para o primeiro elemento interativo
    if(document.getElementById('menu-contact')) {
        document.getElementById('menu-contact').addEventListener('keydown', function(event) {
            if (event.key === 'Tab') {
                const focusableElements = this.querySelectorAll('a, button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                const firstElement = document.getElementsByClassName('box-header-close')[0];
                const lastElement = focusableElements[focusableElements.length - 1];

                if (event.shiftKey) {
                    if (document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    }
                } else {
                    if (document.activeElement === lastElement) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            }
        });
    }
})(jQuery, window);

// MASCARAS
function buttonControll(result, element) {
    element.css({ opacity: result ? 1 : 0.5 });
    element.prop("disabled", result ? false : true);
}
function cpfValidate(cpf) {
    element = $(".form-view-03 form button");
    cpf = cpf.replace(/\D/g, "");
    if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) {
        return buttonControll((result = false), element);
    }
    var result = true;
    [9, 10].forEach(function(j) {
        var soma = 0,
            r;
        cpf.split(/(?=)/)
            .splice(0, j)
            .forEach(function(e, i) {
                soma += parseInt(e) * (j + 2 - (i + 1));
            });
        r = soma % 11;
        r = r < 2 ? 0 : 11 - r;
        if (r != cpf.substring(j, j + 1)) result = false;
    });

    $(window).ready(function() {
        if ($(window).width() <= 767) {
            $(".points-category-carousel").addClass("points-carousel");
            $(".points-carousel").slick({
                slidesToShow: 2,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            centerMode: true,
                            initialSlide: 1
                        }
                    }
                ]
            });
        } else {
            $(".points-category-carousel").removeClass("points-carousel");
        }
    });
    return buttonControll(result, element);
}

const options = {
    onComplete: function(cpf) {
        cpfValidate(cpf);
    },
    onKeyPress: function(cpf) {
        cpfValidate(cpf);
    },
    onChange: function(cpf) {
        cpfValidate(cpf);
    },
    onInvalid: function(cpf) {
        cpfValidate(cpf);
    }
};

$(".form-view-02 form button").css({ opacity: 0.5 });
$(".form-view-02 form button").prop("disabled", true);

$("#name").mask("Z", {
    translation: {
        Z: {
            pattern: /[a-zA-Z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/,
            recursive: true
        }
    }
});

function validateEmail(email, element) {
    var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!re.test(String(email).toLowerCase())) {
        return element[0].setCustomValidity("E-mail inválido!");
    } else {
        return element[0].setCustomValidity("");
    }
}

$("#email, .emailInput, #first-access-email").mask("#", {
    translation: {
        "#": {
            pattern: /[a-zA-Z0-9-@.-_]/,
            recursive: true
        }
    },
    onKeyPress: function(email, event, currentField, options) {
        validateEmail(email, currentField);
    }
});

$("#login-register :input").each(function() {
    let btnStep = $(this)
        .closest(".login-input")
        .closest("form")
        .find(".btn-step");
    let result = $(this)[0].validity.valid;
    btnStep.css({ opacity: result ? 1 : 0.5 });
    btnStep.prop("disabled", result ? false : true);
});

$("#login-register :input").on("blur, keydown, keyup, input", function() {
    let btnStep = $(this)
        .closest(".login-input")
        .closest("form")
        .find(".btn-step");
    let result = $(this)[0].validity.valid;
    btnStep.css({ opacity: result ? 1 : 0.5 });
    btnStep.prop("disabled", result ? false : true);
});

$("#login-register select").each(function() {
    let btnStep = $(this)
        .closest(".login-input")
        .closest("form")
        .find(".btn-step");
    let result = $(this).val();
    btnStep.css({ opacity: result ? 1 : 0.5 });
    btnStep.prop("disabled", result ? false : true);
});

$("#login-register select").on("blur, keydown, keyup, input, change", function() {
    let btnStep = $(this)
        .closest(".login-input")
        .closest("form")
        .find(".btn-step");
    let result = $(this).val();
    btnStep.css({ opacity: result ? 1 : 0.5 });
    btnStep.prop("disabled", result ? false : true);
});

$("#cpf").mask("000.000.000-00", options);
$("#forgotCpf").mask("000.000.000-00", options);
$("#emailInput").mask("000.000.000-00", options);
$("#celular, input[name='tel1'], input[name='tel2'], input[name='tel3']").mask(
    function(val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    {
        onKeyPress: function(val, e, field, options) {
            field.mask(val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009', options);
        }
    }
);
$("#cep, input[name='zipcode']").mask("00000-000");

$("#copyurl").click(function() {
    var aux = document.createElement("input");
    aux.setAttribute("value", window.location.href);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
});

$(".btn-upload").click(function() {
    $(".avatar-edit label").show();
    $("#profile-image").addClass("avatar-preview-hover");
});

$(".btn-upload-save").click(function() {
    $(".avatar-edit label").hide();
    $("#profile-image").removeClass("avatar-preview-hover");
});

$(".btn-close-ad").click(function() {
    $("#banner-adblock").hide();
    window.localStorage.setItem("hideAdblockbanner", "hideAdblockbanner");
    let date = new Date();
    window.localStorage.setItem(
        "dateHideAdblockbanner",
        date.setDate(date.getDate() + 30)
    );
});

document.addEventListener('DOMContentLoaded', function () {
    const uploadAvatarMessageBox = document.getElementById('upload-avatar-message');

    if(uploadAvatarMessageBox) {
        const closeButton = document.getElementById('close-upload-avatar-message');

        closeButton.addEventListener('click', function (event) {
            event.preventDefault();
            uploadAvatarMessageBox.classList.add('fade-out');

            setTimeout(() => {
                uploadAvatarMessageBox.style.display = 'none';
            }, 500);
        });
    }
});
