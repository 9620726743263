import moment from "moment";

if($('#news-list').length) {
    Vue.component('news-categories', () => import('./news-categories.vue'))
    Vue.component('news-categories-carousel', () => import('./news-categories-carousel.vue'))
    Vue.component('lasted-news', () => import('./lasted-news.vue'))
}

const dataCategory = $('#news-list').data('category')
const hasSlug = (dataCategory !== undefined && dataCategory !== '') ? dataCategory : undefined

export default $('#news-list').length ? new Vue({
    el: '#news-list',
    data: {
        model: {},
        categories: {},
        currentCategory: {},
        isMobile: $(window).width() <= 767 ? true : false,
    },
    created: function(){
        this.getCategories()
    },
    mounted: function(){
        //
    },
    updated: function () {
        var self = this
        self.$nextTick(function () {
            setTimeout(async function(){
                const listItem = $('.categoryNewsMobile').not('.slick-initialized').slick({
                    dots: false,
                    arrows: false,
                    infinite: true,
                    speed: 300,
                    centerMode: true,
                    variableWidth: true,
                    focusOnSelect: true,
                });

                listItem.on('afterChange', async function(event, slick, currentSlide, nextSlide){
                    const elementSlick = $(slick.$slides[currentSlide])[0];
                    const category = elementSlick.dataset.category
                    self.getItems(category)
                    self.currentCategory = category
                    window.history.pushState([], `Dell Network Expert - Notícias`, `/noticias/${category}`);
                });
                const slideIndex = listItem.find('li[data-category='+self.currentCategory+']').index()
                listItem.slick('slickGoTo', parseInt(slideIndex-2))
            }, 1000)
        })
    },
    methods:{
        async getItems(category, page = 1){
            var self = this;
            category = (category == 'all') ? '' : category;
            let response = await $.ajax(
                {
                    url: `${api_url}news?active=1&per_page=4&sort=-date,-id&slugcategory=${category}&page=${page}`,
                    headers: $headerAuth,
                }                
            )
            

            response.data.forEach(news => {
                news.date = moment(news.date).format('DD/MM/YYYY HH:mm')
            });

            self.model = response
        },
        getItemsByPage(page){
            this.getItems(this.currentCategory, page)
            $([document.documentElement, document.body]).animate({
                scrollTop: $('#news-list').offset().top - 100
            }, 0);
        },
        async getCategories() {
            var self = this
            let response = await $.ajax({
                url: `${api_url}news/categories?status=1`,
                headers: $headerAuth
            })
            response.data = response.data.filter(category => category.news_count > 0)
            // const category = hasSlug || response.data[0].slug
            
            const filtered = response.data.filter(function (el) {
                return el.slug == hasSlug
            });

            const category = (filtered.length > 0) ? hasSlug : 'all';
            if ($('#news-list').length) self.updateCategory(category)
            self.categories = response
        },
        async updateCategory(category) {
            var self = this
            var param = (category == 'all') ? '' : category;
            self.getItems(param);
            param = (category == 'all') ? param : '/' + param;
            window.history.pushState([], `Dell Network Expert - Notícias`, `/noticias${param}`);
            self.currentCategory = category;

            $('#news-list > div.section > div > div.news-container > div.news-list.tab-content > div > div:nth-child(1) .link-detail a').focus();
        }
    }
}) : {}
