var slugtraining = $('#training-detail').data("slug");
new Vue({
    el: '#training',
    data: {
        model: {},
        feedbacks: {},
        completed: false,
        registered: false,
        timerEnabled: false,
        completed_watched: false,
        question_finished: false,
        watched: 0,
        fields_feedback: {},
        fields_evalution: {
            message: null,
            chances: null,
            grade: null,
        },
        timeVideo: 0,
        tried: 0,
        certificate: null,
    },
    created: function(){
        this.get(slugtraining);
    },
    mounted: function(){},
    methods:{
        async get(slug){
            var self = this;

            let response = await axios.get(`${api_url}training/${slug}`, {headers : $headerAuth});
            var trainings = response.data;
            for (const key in trainings.evaluations) {
                let training = trainings.evaluations[key];
                training.options = self.shuffle(training.options);
            }

            self.registered = (trainings.subscriber) ? true : false;
            self.completed = (trainings.subscriber) ? (trainings.subscriber.completed == 1) : false;
            self.completed_watched = self.completed;
            self.question_finished = (trainings.subscriber) ? (((trainings.subscriber) && (trainings.subscriber.feedback.length == 0)) ? false : true) : false;
            self.model = trainings;
            self.certificate = (trainings.subscriber) ? trainings.subscriber.certificate : null;
            self.model.size_certificate = (trainings.subscriber) ? trainings.subscriber.size_certificate : null;
            self.model.evaluations = (trainings.evaluations.length > 0) ? self.shuffle(trainings.evaluations) : false;
            self.model.materials = (trainings.materials.length > 0) ? trainings.materials : false;
            self.tried = (trainings.subscriber) ? trainings.subscriber.tried : null;

            var video= document.getElementById("video-treinamento");
            video.load();
            video.currentTime = (self.registered) ? trainings.subscriber.time_watched : 0;

            if(self.registered) self.goto('videos');
            if(self.completed) self.createCertificate(self.model.id);
        },
        getVideoDuration(){
            var self = this, video= document.getElementById("video-treinamento");
            if((Math.floor(video.duration) == Math.floor(video.currentTime)) ){
                self.completed_watched=true;
                self.completed=true;
                self.getFeedback();
                self.createCertificate(self.model.id);
            }
            else{
                self.completed_watched=false;
            }
        },
        subscribe(id){
            var self = this;
            self.tried = 0;
            self.completed_watched = false;

            self.goto('videos');

            axios.put(
                `${api_url}training/subscribe/${id}`,
                {
                    'time_watched' : 0,
                    'tried':0,
                }
                ,{headers : $headerAuth}
            ).then(function (data) {
                self.registered = true;

                var video= document.getElementById("video-treinamento");
                video.currentTime = 0;

                self.fields_evalution['message']= null;
                self.fields_evalution['chances']= null;
                self.fields_evalution['grade']= null;
            })
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao cadastrar no treinamento.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });
        },
        play(id) {
            var self = this;

            self.timerEnabled = setInterval(() => {
                self.saveProgress(id);
            }, 5000);
        },
        pause(id) {
            var self = this;

            clearInterval(self.timerEnabled);
            self.saveProgress(id);
        },
        async saveProgress(id){
            var self = this;

            var video = document.getElementById("video-treinamento");
            var watched = Math.floor(video.currentTime);
            video = Math.floor(video.duration);

            var data = {};
            var progress = (watched * 100) / video;
            self.completed_watched = (progress >= 100) ? true : false;

            if(self.completed_watched){
                if(self.model.evaluations){
                    self.disableTabs();
                    $('#button-evaluation-tab').addClass('active');
                    $('#pills-evaluation').addClass('active').addClass('show');
                    self.goto('evaluation');
                }
                else{
                    self.completed = self.completed_watched;
                    if(!self.certificate){
                        let response = await axios.get(`${api_url}training/${id}/certified`, {headers : $headerAuth});
                        self.certificate = response.data[0]['certificate'];
                        self.model.size_certificate = response.data[0]['size_certificate'];
                    }

                    self.disableTabs();
                    $('#button-certified-tab').addClass('active');
                    $('#pills-certified').addClass('active').addClass('show');
                }
            }

            data['time_watched'] = watched;
            data['completed'] = self.completed;

            const axiosWithoutInterceptors = axios.create();
            await axiosWithoutInterceptors.put(
                `${api_url}training/progress/${id}`,data,
                {headers : $headerAuth}
            ).then(function (data) {
                if(self.completed_watched) self.getFeedback();
            })
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao salvar progresso.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });

        },
        goto(refName) {
            var self = this;
            var element = this.$refs[refName];
            var top =  element.offsetTop;
            if(top > 0){
                window.scrollTo({
                    top: top,
                    behavior: 'smooth',
                });
                element.scrollIntoView({behavior: 'smooth'});
            }
            else{
                setTimeout(function(){ self.goto('videos') }, 500);
            }
        },
        async getFeedback(){
            var self = this;
            let response = await axios.get(`${api_url}training/feedback`, {headers : $headerAuth});
            self.feedbacks = response.data;
        },
        closeFeedback(){
            this.completed_watched = false;
        },
        async sendFeedback(id){
            var self = this;

            if(self.fields_feedback.length < 0){
                $('#danger-message').show();
                $('#danger-message').html('*Preencher ao menos um campo.');
                return false;
            }

            await axios.post(
                `${api_url}training/${id}/feedback`,
                { 'questions': self.fields_feedback},
                {headers : $headerAuth}
            ).then(function (response) {
                self.question_finished = response.data.success;

                self.disableTabs();
                if(self.model.evaluations){
                    $('#button-evaluation-tab').addClass('active');
                    $('#pills-evaluation').addClass('active').addClass('show');
                    self.goto('evaluation');
                }
                else{
                    $('#button-certified-tab').addClass('active');
                    $('#pills-certified').addClass('active').addClass('show');
                }
            })
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao salvar progresso.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });
        },
        convertToHour(){
            var video= document.getElementById("video-treinamento");

            var time = video.duration;
            if(time > 60){
                time = (time/60).toFixed(2);
                time = (time >= 60) ? (time / 3600).toFixed(2) + 'h' : time + 'min';
            }
            else{
                time = Math.floor(time) + 's';
            }

            this.timeVideo = time;;
        },
        async createCertificate(id){
            let response = await axios.get(`${api_url}training/${id}/certified`, {headers : $headerAuth});
            this.certificate = response.data[0]['certificate'];
            this.model.size_certificate = (response.data[0]['size_certificate'] > 0) ? response.data[0]['size_certificate'] : this.getImageSize(this.certificate);
        },
        async downloadCertified(id) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/png");
            myHeaders.append("Authorization", $headerAuth['Authorization']);

            var url = `${api_url}training/${id}/certified/download`;

            var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };

            var myRequest = new Request(url, myInit);

            fetch(url, myRequest)
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = url.replace(/^.*[\\\/]/, '');
                a.href = blobUrl;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        },
        async downloadMaterial(id) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/png");
            myHeaders.append("Authorization", $headerAuth['Authorization']);

            var url = `${api_url}training/material/download/${id}`;

            var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };

            var myRequest = new Request(url, myInit);

            fetch(url, myRequest)
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = url.replace(/^.*[\\\/]/, '');
                a.href = blobUrl;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        },
        shuffle(array) {
            for (var i = 0; i < array.length - 1; i++) {
                var j = i + Math.floor(Math.random() * (array.length - i));

                var temp = array[j];
                array[j] = array[i];
                array[i] = temp;
            }
            return array;
        },
        async sendEvaluation(id){
            var obj = {},  self = this;

            $('input[name^=fields_evaluation]:checked').each(function(){
                let name = $(this).attr('name');
                var name2 = name.replace(']',''); name2 = name2.split('[');
                obj[name2[name2.length - 1]] = $(this).val();
            });

            if(!obj){
                $('#danger-message').show();
                $('#danger-message').html('*Preencher ao menos um campo.');
                return false;
            }

            await axios.post(
                `${api_url}training/${id}/evaluation`,
                { 'questions': obj},
                {headers : $headerAuth}
            ).then(function (response) {
                if(!response.data.data){
                    self.fields_evalution['message'] = 'Acabou suas tentativas, favor, refaça novamente o treinamento.';
                }
                else{
                    let chances = 3 - parseInt(response.data.data['chances']);
                    let grade = Math.floor(parseFloat(response.data.data['grade']));
                    let approved = (grade >= 80) ? 'Aprovado' : 'Reprovado';
                    self.completed = (grade >= 80) ? true : false;
                    self.tried = parseInt(response.data.data['chances']);
                    self.fields_evalution['message'] = `Infelizmente não foi desta vez, você tem mais ${chances} tentativas.`;
                    self.fields_evalution['grade'] = `Nota da avaliação: ${grade}/100   -   ${approved}`;
                    if(self.completed){
                        self.fields_evalution['message'] = 'Parabéns, você está aprovado!';

                        axios.get(`${api_url}training/${id}/certified`, {headers : $headerAuth})
                        .then(function(resp){
                            self.certificate = resp.data[0]['certificate'];
                            self.model.size_certificate = resp.data[0]['size_certificate'];

                            if(self.certificate){
                                $('.nav-link').removeClass('active');
                                $('.tab-pane').removeClass('active').removeClass('show');
                                $('#button-certified-tab').addClass('active');
                                $('#pills-certified').addClass('active').addClass('show');
                            }
                        });
                    }
                    else{
                        document.getElementById("form-evaluation").reset();
                    }
                }
            })
            .catch(error => {
                $('#danger-message').show();
                if ((error) && (error.response)) {
                    if (error.response.statusText) {
                        $('#danger-message').html(error.response.statusText);
                    }
                    else {
                        $('#danger-message').html('*Erro ao salvar progresso.');
                    }
                }
                else {
                    $('#danger-message').html(error.message);
                }

                $('body').css({ 'opacity': '1' });
                setTimeout(() => {
                    $('.m-alert').fadeOut('slow');
                    $('.request-buttons button[type=submit]').prop('disabled', false);
                }, 3000);
            });
        },
        disableTabs(){
            $('.nav-link').removeClass('active');
            $('.tab-pane').removeClass('active').removeClass('show');
        },
        getImageSize(imageUrl){
            if (imageUrl) {
                var self = this, image = new Image();
                image.src = imageUrl;
                var height = image.height;
                var width = image.width;
                let a = width * height;
                let kb = (a * 2)/1024;
                let mb = kb/1024;
                return mb.toFixed(2);
            }
        }
    },
    filters: {
        strippedContent: function(string) {
            return string.replace(/<\/?[^>]+>/ig, " ");
        }
    }
})
