(function($, w) {
    if (localStorage.getItem("user_id")) {

        //user is logged in
        require("../user/userAvatar.js");
        require("../user/userNotifications.js");
        if ($('#points').length) require("../user/userPoints.js");
        if ($('#menu-contact').length) require("../user/userConsultants");

        $('.auth-show').css({'display':'block'});
        $('.auth-hide').css({'display':'none'});
        $('.menu-contact').css({'display':'none'});
        $('.menu-contact-user').css({'display':'block'});
        $('.product-list').addClass("user-login-product");
        $('.product-detail').addClass("user-login-product");

        if(!localStorage.disableModals) localStorage.disableModals = true;

    } else {

        //user is not logged in
        $('.auth-show').css({'display':'none'});
        $('.product-list').removeClass("user-login-product");
        $('.product-detail').removeClass("user-login-product");
        $('.menu-contact').css({'display':'block'});
        $('.menu-contact-user').css({'display':'none'});

        //conditionals for modals
        if(!localStorage.getItem('disableModals')) {

            //outboundModal
            $([document.documentElement]).mouseleave(function(){
                let today = new Date();
                let expirationDate = new Date(localStorage.getItem('expirationForDisableOutboundModal'));
                new Date(expirationDate);
                if (today >= expirationDate && !$('#login').length) {
                    let after1Month = new Date(today.setMonth(today.getMonth()+1));
                    $('#outbound-modal').modal('show');
                    localStorage.expirationForDisableOutboundModal = after1Month;
                }
            });

            // adds 1 for numberOfVisits for each new session
            var numberOfVisits = localStorage.getItem('numberOfVisits') || 0;
            if(!sessionStorage.getItem('sessionVisit')){
                numberOfVisits = parseInt(numberOfVisits) + 1;
                localStorage.numberOfVisits = numberOfVisits;
                sessionStorage.setItem('sessionVisit', true);
            }

            function welcomeModal() {
                let today = new Date();
                let expirationDate = localStorage.getItem('expirationForDisableWelcomeModal');
                let validateDate = expirationDate ? new Date(expirationDate) : new Date();
                if (today >= validateDate && numberOfVisits > 1 && !$('#login').length) {
                    let after1Month = new Date(today.setMonth(today.getMonth()+1));
                    $('#welcome-modal').modal('show');
                    localStorage.expirationForDisableWelcomeModal = after1Month;
                }
            }
            welcomeModal();
        }

        const uploadAvatarMessageBox = document.getElementById('upload-avatar-message');

        if (uploadAvatarMessageBox) {
            uploadAvatarMessageBox.style.display = 'none';
        }
    }
})(jQuery, window);
