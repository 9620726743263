new Vue({
    el: '#testimonials',
    data: {
        model: {},
    },
    created: function(){
        this.getItems();
    },
    mounted: function(){
    },
    updated: function () {
        this.$nextTick(function () {
            $('.banner-testimonials').slick({
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                adaptiveHeight: true,
                arrows: true,
                // autoplay: true
            })
        })
    },
    methods:{
        async getItems(){
            var self = this
            let response = await axios.get(`${api_url}user/testimonials/approved`)
            let testimonials = response.data

            testimonials.forEach(testimonial => {
                const array_name = testimonial.user.name.split(" ").filter(el => el != '')
                testimonial.user.firstName = array_name[0]
                testimonial.user.lastName = array_name[array_name.length-1]
                testimonial.user.letters = [
                    testimonial.user.firstName.charAt(0),
                    testimonial.user.lastName.charAt(0)
                ]
            });

            self.model = testimonials
        },
    }
});
