import slideToShow3 from '../slicksettings/slideToShow3'

new Vue({
    el: '#trainings-list',
    data: {
        model: {},
    },
    created: function(){
        var currentUrl = window.location.pathname;
        if( currentUrl == "/videos") {
            this.getItemsAll();
        } else {
            this.getItems();
        }
    },
    mounted: function(){
        //
    },
    updated: function () {
        this.$nextTick(function () {
            if($(window).width() > 992) $('.slick-lg-trainings').slick(slideToShow3)
        })
    },
    methods:{
        async getItems(){
            let account = await $getAccount
            let response = await axios.get(
                `${api_url}material/training?active=1&per_page=5${(account ? "&restrict=" + account.category.id : "")}`
            )
            this.model = response.data
        },
        async getItemsAll(){
            let account = await $getAccount
            let response = await axios.get(
                `${api_url}material/training?active=1${(account ? "&restrict=" + account.category.id : "")}`
            )
            this.model = response.data
        },
        truncate(text) {
            if (text.length > 130) {
               return text.substring(0, 130) + '...';
            }
            return text;
        }
    }
});

