$(document).ready(function () {
    $.ajax({
        crossDomain: true,
        url: api_url + 'banner?active=1&per_page=5',
        contentType: "application/json",
        dataType: 'json',
        headers: {
            Authorization: localStorage.token,
        },
        success: function (result) {
            window.banners = result.data;
            const isMobile = $(window).width() <= 767 ? true : false;
            for (i = 0; i < result.data.length; i++) {
                const banner_image = isMobile ? result.data[i].image_mobile_url : result.data[i].image_url;
                if (banner_image) {
                    let b = '<div class="banner-box">'
                        + '<div class="banner-home-img">'
                        + '<img src="' + banner_image + '" alt="' + result.data[i].name + '" style="object-fit: cover;object-position: center;width: 100%;height: 100%;">'
                        + '</div>'
                        + '<div class="container">'
                        + '<div class="col-lg-6 banner_text col-md-12">'
                        + '<div class="banner_text_iner">'
                        + '<h2>' + result.data[i].name + '</h2>';

                    if (result.data[i].description) {
                        b += '<p>' + result.data[i].description + '</p>';
                    }

                    if (result.data[i].url) {
                        href = result.data[i].url;

                        b += '<div class="btn-box">'
                            + '<a href="' + href + '" class="btn-banner" ' + (result.data[i].external ? 'target="_blank"' : '') + ' role="button" alt="Saiba mais sobre ' + result.data[i].name + '" tabindex="0">Saiba mais</a>'
                            + '</div>'
                            + '<div class="banner-box"></div>';
                    }

                    b += '</div>'
                        + '</div>'
                        + '</div>'
                        + '</div>';

                    $('#banner-container').append(b);
                }
            };

            const startAt = result.data.length;

            $('.banner-home').slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                initialSlide: startAt,
                adaptiveHeight: true,
                autoplay: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            dots: true,
                            arrows: false,
                        }
                    }
                ]
            });

            $('.banner-home').slick('slickGoTo', startAt);

            var bannerHomeSlides = document.querySelectorAll('.banner-home .banner-box.slick-slide:not(.slick-cloned)');

            bannerHomeSlides.forEach((el, index) => {
                el.setAttribute('role', 'group');
                el.setAttribute('aria-label', `Slide ${index + 1} de ${bannerHomeSlides.length}`);
            });
        }
    })
});
