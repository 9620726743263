import moment from "moment";

moment.locale('pt-br');

new Vue({
    el: '#extracts',
    data: {
        model: {},
    },
    created: function () {
        this.getItems();
    },
    mounted: function () {
    },
    methods: {
        async getItems(page = 1) {
            var self = this;
            let response = await axios.get(
                `${api_url}account/extract?page=${page}`,
                {headers: $headerAuth}
            );
            let extracts = response.data;
            extracts.data.forEach(extract => {
                extract.date = moment(extract.order_date, "YYYY-MM-DD H:mm:ss").calendar();

                extract.expire_date = extract.order_expiredate ?
                    moment(extract.order_expiredate, "YYYY-MM-DD H:mm:ss").format('DD/MM/YYYY') : '-'

                extract.showExpireTitle = !!extract.order_expiredate

                extract.reais = extract.reais != null ?
                    extract.reais.addDotE3d() : extract.reais

                extract.balance_reais = extract.balance_reais != null ?
                    extract.balance_reais.addDotE3d() : extract.balance_reais

                extract.points = extract.points != null ?
                    extract.points.addDotE3d() : extract.points

                extract.balance_points = extract.balance_points ?
                    extract.balance_points.addDotE3d() : extract.balance_points
            });

            self.model = extracts;
        },
        async getItemsByPage(page) {
            await this.getItems(page);
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#extracts").offset().top - 100
            }, 0);
        },
    }
});
