const hasSlug = {
    status: $('#product-list').data('category') !== undefined ? true : false,
    category: $('#product-list').data('category'),
    type: $('#product-list').data('type'),
}
var load_category = 0;
let nextTabNumber = 0;
const rescueProductsFiltered = $RESCUE_PRODUCTS.filter(el => el != null);
new Vue({
    el: '#product-list',
    data: {
        typeSelected: {},
        types: {},
        type_favorite: {
            active: true,
            categories: Array(0),
            categories_count: 0,
            name: "Favoritos",
            slug: "favoritos",
        },
        products: {},
        currentType: '',
        currentCategory: '',
        paginate: {},
        rescueProductsPoints: rescueProductsFiltered.reduce((total, purchase) => total + (purchase.points * purchase.qty), 0),
        rescueProductsQty: rescueProductsFiltered.reduce((total, purchase) => total + purchase.qty, 0),
        credits: 0,
        isMobile: $(window).width() <= 767 ? true : false,
        slickMobile: {},
        loading: false
    },
    created: async function () {
        if ($getAccount) await this.getAccount();
        await this.getTypes();
    },
    mounted: function () {
        $('.close-add').click(function () {
            $('.close-add').css({ 'display': 'none' });
        });
    },
    updated: function () {
        var self = this
        if (self.isMobile) {
            self.$nextTick(function () {

                const listItem = $('.list-item').not('.slick-initialized').slick({
                    dots: false,
                    arrows: false,
                    infinite: true,
                    speed: 300,
                    centerMode: true,
                    variableWidth: true,
                    focusOnSelect: true,
                });

                listItem.on('afterChange', function (event, slick, currentSlide, nextSlide) {
                    const elementSlick = $(slick.$slides[currentSlide])[0];
                    self.getProducts(elementSlick.dataset.type, elementSlick.dataset.category);
                });

                setTimeout(function () {
                    const slideIndex = listItem.find('li button.active').closest('li').index()
                    listItem.slick('slickGoTo', parseInt(slideIndex - 2))
                }, 1000)

            })
        }
    },
    methods: {
        async getTypes() {
            let response2 = await axios.get(
                `${api_url}product?active=1&per_page=9&favorite=1`,
                { headers: $headerAuth }
            );
            let products = response2.data.data;
            let vcategories = [];
            products.forEach(product => {
                let rept = 0;
                for (let i = 0; i < vcategories.length; i++) {
                    const element = vcategories[i]['name'];
                    if (element === product.category.name) {
                        rept++;
                    }
                }

                if (rept <= 0) vcategories.push(product.category);
            });
            this.type_favorite.categories = vcategories;

            let response = await axios.get(
                `${api_url}product/categories/types`,
                { headers: $headerAuth }
            );
            this.types = response.data;
            this.types.forEach(type => {
                if (type.categories.length && !load_category && !hasSlug.status) {
                    this.getProducts(type.slug, type.categories[0].slug);
                    this.currentCategory = type.categories[0].slug;
                    this.currentType = type.slug;
                    load_category = 1;
                }
            });

            let info = await $getUser

            if (info) this.types = this.types.concat(this.type_favorite);

            if (hasSlug.status) {
                this.getProducts(
                    hasSlug.type,
                    hasSlug.category,
                    false,
                    (hasSlug.category === "favoritos" ? true : false)
                );
                this.currentCategory = hasSlug.category;
                this.currentType = hasSlug.type;
            }
        },
        async getProducts(type, category, page = 1, favorite = false) {
            var self = this;

            if (self.loading)
                return

            self.loading = true

            let response = await axios.get(
                `${api_url}product?active=1&per_page=9&${favorite ? 'favorite=1' : 'slugcategory=' + category}&page=${page}`,
                { headers: $headerAuth }
            );

            let products = response.data.data;
            products.forEach(product => {
                if (typeof product.image[0] === "undefined") {
                    product.image[0] = { thumb: 'https://via.placeholder.com/357x167/' };
                }
                product.qty = 1;
                product.description = product.description.cutWord();
                product.url = '/' + product.category.type.slug + '/' + product.category.slug + '/' + product.slug;
            });

            category = ((category == "favoritos") && (response.data.data.length > 0)) ? response.data.data[0].category.slug : category;

            self.products = products;
            self.currentCategory = category;
            self.currentType = type;
            self.paginate = response.data;
            self.typeSelected = self.getType(type);

            setTimeout(function () {
                self.loading = false
            }, 500);

            window.history.pushState([], `Dell Network Expert - Premiações`, `/${type}/${category}`);
        },
        async getAccount() {
            var self = this;
            let response = await $getAccount;
            self.credits = response.credits;
        },
        getProductsByPage(page) {
            const is_favorite = this.currentCategory == 'favoritos' ? true : false;
            this.getProducts(this.currentType, this.currentCategory, page, is_favorite);
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#product-list").offset().top - 100
            }, 0);
        },
        changeQty(moreOrLess, product_id) {
            var self = this;
            self.products.forEach(product => {
                if (product_id == product.id)
                    product.qty = moreOrLess ? ++product.qty : (product.qty < 2 ? 1 : --product.qty);
            });
        },
        async changeRescueProducts(data) {
            var self = this;
            let temp_rescue_products = await updateRescueProduct(data);
            self.rescueProductsQty = temp_rescue_products.reduce((total, rescueProduct) => total + rescueProduct.qty, 0);
            self.rescueProductsPoints = temp_rescue_products.reduce((total, purchase) => total + (purchase.points * purchase.qty), 0);
        },
        async toggleFavorite(product_id) {
            var self = this;
            const response = await axios.post(`${api_url}product/${product_id}/favorite`, {}, { headers: $headerAuth });
            const { is_favorite } = response.data;
            self.products.forEach(product => {
                if (product_id == product.id) product.is_favorite = is_favorite ? true : false;
            });
            if (self.currentCategory == 'favoritos') self.getFavorites();
        },
        getFavorites(type) {
            var self = this;
            // self.getProducts(type, 'favoritos', false, true);
            self.getElementFavorites(type);
            self.getTypes();
        },
        async getElementFavorites(type) {
            var self = this;

            let response = await axios.get(
                `${api_url}product?active=1&per_page=9&favorite=1&page=1`,
                { headers: $headerAuth }
            );

            var category = ((type === "favoritos") && (response.data.data.length > 0)) ? response.data.data[0].category.slug : 'favoritos';

            self.getProducts(type, category);
        },
        changeType(type, category) {
            var self = this;
            self.getProducts(type, category)
        },
        getType(slug) {
            return this.types.filter((type) => type.slug === slug)[0]
        }
    }
});

document.addEventListener('keydown', function (event) {
    let $Tabs = $('ul[role="tablist"] li a[role="tab"]').toArray();

    if (event.keyCode === 37) { // Left arrow key
        if(nextTabNumber === 0) {
            nextTabNumber = ($Tabs.length - 1);
            $Tabs[nextTabNumber].focus(); 
        } else {
            nextTabNumber = nextTabNumber - 1;
            $Tabs[nextTabNumber].focus();
        }
    } else if (event.keyCode === 39) { // Right arrow key
        if(nextTabNumber === ($Tabs.length - 1)){
            nextTabNumber = 0;
            $Tabs[nextTabNumber].focus(); 
        } else {
            nextTabNumber = nextTabNumber + 1;
            $Tabs[nextTabNumber].focus();
        }
    }
});

document.addEventListener('keydown', function (event) {
    if (event.keyCode === 32) { // Spacebar
        event.preventDefault();
        $(':focus').trigger('click');
    }
});