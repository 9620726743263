Vue.component('custom-select', () => import('./custom-select.vue'))
Vue.component('my-materials', () => import('./my-materials.vue'))

export default new Vue({
    el: '#my-learning',
    data: {
        trainings: [],
        paginate: {},
        isMobile: $(window).width() <= 767 ? true : false,
        loading: false,
        options: ['Todos os treinamentos', 'Concluídos', 'Em andamento', 'Não iniciados', 'Reprovados'],
        initialOption: "Todos os treinamentos",
        materials: null,
        material_name: null,
        input:{
            name: '',
            status: 0,
        },
        certificate: {
            background: '',
            id: '',
        },
    },
    created: function () {
        this.getTrainings();
    },
    updated: function () {},
    methods: {
        setTrainings(value){
            this.trainings = value;
        },
        setPaginate(value){
            this.paginate = value;
        },
        async getTrainings(page = 1) {
            var self = this;

            if (self.loading) return;

            self.input.name = (self.input.name) ? `&name=${self.input.name}` : ``;
            self.input.status = (self.input.status) ? `&status=${self.input.status}` : ``;

            self.loading = true;
            let response = await axios.get(
                `${api_url}training?mycourse=true&active=1&per_page=8&page=${page}${self.input.name}${self.input.status}`,
                {headers: $headerAuth}
            );

            let trainings = response.data.data;
            let column = 0;
            trainings.forEach(training => {
                let c = (training.category) ? training.category.slug : '';
                training.content = training.content.cutWord();
                training.certificate = training.subscriber.certificate;
                training.url =  '/treinamentos/' + c + '/' + training.slug;
                column = (column > 3) ? 0 : column;
                training.column = column;
                column++;
                training.materials = (training.materials.length > 0) ? training.materials : false;
                if (training.subscriber) {
                    training.subscriber.evaluation = (training.subscriber.evaluation.length > 0) ? training.subscriber.evaluation : false;
                }
            });

            self.trainings = trainings;

            self.paginate = response.data;

            setTimeout(function () {
                self.loading = false
            }, 500);
        },
        getTrainingsByPage(page) {
            this.getTrainings(page);
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#my-learning").offset().top - 100
            }, 0);
        },
        showCertificate(training){
            this.certificate.background = training.certificate;
            this.certificate.id = training.id;
        },
        showMaterial(training){
            this.materials = training.materials;
            this.material_name = training.name;
        },
        async downloadCertified(id) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/png");
            myHeaders.append("Authorization", $headerAuth['Authorization']);

            var url = `${api_url}training/${id}/certified/download`;

            var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };

            var myRequest = new Request(url, myInit);

            fetch(url, myRequest)
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = url.replace(/^.*[\\\/]/, '');
                a.href = blobUrl;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        },
        async downloadMaterial(id) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/png");
            myHeaders.append("Authorization", $headerAuth['Authorization']);

            var url = `${api_url}training/material/download/${id}`;

            var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };

            var myRequest = new Request(url, myInit);

            fetch(url, myRequest)
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = url.replace(/^.*[\\\/]/, '');
                a.href = blobUrl;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        }
    }
});
