var load_category = 0;
var hasSlugCategory = $('#materials-list').data('category') !== undefined ? $('#materials-list').data('category') : false;

new Vue({
    el: '#materials-list',
    data: {
        training: {
            title: 'Treinamentos',
            slug: 'treinamentos',
            description: 'Materiais de treinamentos',
        },
        categories: {},
        materials: {},
        currentCategory: '',
    },
    created: function () {
        this.getCategories();
    },
    mounted: function () {
        setTimeout(function () {
            if (localStorage.getItem("user_id")) {
                $('#materials-list').removeClass("user-restrict");
            } else {
                $('#materials-list').addClass("user-restrict");
                $('.user-restrict .restrict').remove();
            }
        }, 1500);

    },
    methods: {
        async getCategories() {
            let response = await axios.get(
                `${api_url}material/categories?status=1`,
            );
            this.categories = response.data.data;
            this.categories.forEach(category => {
                if (!load_category && !hasSlugCategory && category.materials_count) {
                    this.getMaterials(category.slug);
                    this.currentCategory = category.slug;
                    load_category = 1;
                }
            });
            this.categories = this.categories.concat(this.training);

            if (hasSlugCategory !== this.training.slug) {
                this.getMaterials(hasSlugCategory);
                this.currentCategory = hasSlugCategory;
            }
            else{
                this.getTrainingMaterials(hasSlugCategory);
                this.currentCategory = hasSlugCategory;
            }
        },
        async getMaterials(category) {
            let account = await $getAccount
            let response = await axios.get(
                `${api_url}material?active=1&per_page=12&slugcategory=${(category ? category : "")}${(account ? "&restrict=" + account.category.id : "")}`
            );
            window.material = response.data;
            this.materials = response.data.data;
            window.history.pushState([], `Dell Network Expert - Materiais`, `/materiais/${category}`);
            this.currentCategory = category;
        },
        async getTrainingMaterials(category) {
            let account = await $getAccount
            let response = await axios.get(
                `${api_url}training/material?active=1&per_page=12${(account ? "&restrict=" + account.category.id : "")}`
            );
            window.material = response.data;
            this.materials = response.data.data;
            window.history.pushState([], `Dell Network Expert - Materiais`, `/materiais/${category}`);
            this.currentCategory = category;
        },
    }
});

