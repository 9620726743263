var slugproduct = $('#product-detail').data("slug");
const rescueProductsFiltered = $RESCUE_PRODUCTS.filter(el => el != null);
new Vue({
    el: '#product',
    data: {
        model: {},
        rescueProductsPoints : rescueProductsFiltered.reduce((total, purchase) => total + (purchase.points * purchase.qty), 0),
        rescueProductsQty : rescueProductsFiltered.reduce((total, purchase) => total + purchase.qty, 0),
        credits: 0,
    },
    created: function(){
        this.get(slugproduct)

        if (localStorage.getItem("user_id")) this.getAccount();
    },
    mounted: function(){
        setTimeout(function(){
            if(localStorage.getItem("user_id")) {
                $('.product-detail').addClass("user-login-product");
            }else{
                $('.product-detail').removeClass("user-login-product");
            }

            $('.close-add').click(function(){
                $('.close-add').css({'display':'none'});
            });
        }, 1500);

    },
    methods:{
        async get(slug){
            var self = this;
            let response = await axios.get(`${api_url}product/${slug}`, {headers : $headerAuth})
            let product_version_id = response.data.product_version_id;
            if(product_version_id){
                let versions = await axios.get(`${api_url}product/${product_version_id}/versions`, {headers : $headerAuth})
                response.data.versions = versions.data;
            }

            let product = response.data;

            if(typeof product.image[0] === "undefined") product.image = 'https://via.placeholder.com/357x167/' 
            else product.image = product.image[0].thumb;
            product.qty = 1;
            product.url = `/${product.category.type.slug}/${product.category.slug}/${product.slug}`;

            self.model = product;            
        },
        async getAccount(){
            var self = this;
            let response = await $getAccount;
            self.credits = response.credits;
        },
        check_addDotE3d(value){
            return (value) ? value.addDotE3d() : '';
        },
        optionRender(e){
            var self = this
            if(e.target.value != '') {
                window.history.pushState([], `Dell Network Expert - Produto`, `/${self.model.category.type.slug}/${self.model.category.slug}/${e.target.value}`);
                self.get(e.target.value)
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#product-detail").offset().top - 150
                }, 0);
            }
        },
        changeQty(moreOrLess){
            var self = this;
            self.model.qty = moreOrLess ? ++self.model.qty : (self.model.qty < 2 ? 1 : --self.model.qty);
        },
        async changeRescueProducts(data){
            var self = this;
            let temp_rescue_products = await updateRescueProduct(data);
            self.rescueProductsQty = temp_rescue_products.reduce((total, rescueProduct) => total + rescueProduct.qty, 0);
            self.rescueProductsPoints = temp_rescue_products.reduce((total, purchase) => total + (purchase.points * purchase.qty), 0);
        },
        async toggleFavorite(){
            var self = this;
            const response = await axios.post(`${api_url}product/${self.model.id}/favorite`, {}, {headers : $headerAuth});
            const { is_favorite } = response.data;
            self.model.is_favorite = is_favorite ? true : false;
        }
    },
    filters: {
        strippedContent: function(string) {
            return string.replace(/<\/?[^>]+>/ig, " ");
        }
    }
})
